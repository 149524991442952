import React from 'react';
import { Route } from 'react-router';
import { Navigate } from 'react-router-dom';
import { MasterClientPage } from '../components/pages/Master/Client/IndexPage';
import { MasterClientNewPage, MasterClientDetailPage } from '../components/pages/Master/Client/ViewPage';
import { MasterCompanyPage } from '../components/pages/Master/Company/IndexPage';
import { MasterCompanyNewPage, MasterCompanyDetailPage } from '../components/pages/Master/Company/ViewPage';
import { MasterClientSetPage } from '../components/pages/Master/ClientSet/IndexPage';
import { MasterClientSetNewPage, MasterClientSetDetailPage } from '../components/pages/Master/ClientSet/ViewPage';
import { MasterMarketPage } from '../components/pages/Master/Market/IndexPage';
import { MasterMarketNewPage, MasterMarketDetailPage } from '../components/pages/Master/Market/ViewPage';
import { MasterMediaTypePage } from '../components/pages/Master/MediaType/IndexPage';
import { MasterMediaTypeNewPage, MasterMediaTypeDetailPage } from '../components/pages/Master/MediaType/ViewPage';
import { MasterSaleHousePage } from '../components/pages/Master/SaleHouse/IndexPage';
import { MasterSaleHouseNewPage, MasterSaleHouseDetailPage } from '../components/pages/Master/SaleHouse/ViewPage';
import { MasterRegionPage } from '../components/pages/Master/Region/IndexPage';
import { MasterRegionNewPage, MasterRegionDetailPage } from '../components/pages/Master/Region/ViewPage';
import { TradingAgencyProfilePage } from '../components/pages/Trading/AgencyProfile/IndexPage';
import {
  TradingAgencyProfileNewPage,
  TradingAgencyProfileDetailPage,
} from '../components/pages/Trading/AgencyProfile/ViewPage';
import { TradingClientProfilePage } from '../components/pages/Trading/ClientProfile/IndexPage';
import {
  TradingClientProfileNewPage,
  TradingClientProfileDetailPage,
} from '../components/pages/Trading/ClientProfile/ViewPage';
import { TradingDealPage } from '../components/pages/Trading/Deal/IndexPage';
import { TradingDealNewPage, TradingDealDetailPage } from '../components/pages/Trading/Deal/ViewPage';
import { TradingSACConfigPage } from '../components/pages/Trading/SACConfig/IndexPage';
import { TradingSACConfigNewPage, TradingSACConfigDetailPage } from '../components/pages/Trading/SACConfig/ViewPage';
import { TradingRevenueForecastStreamPage } from '../components/pages/Trading/RevenueForecastStream/IndexPage';
import {
  TradingRevenueForecastStreamNewPage,
  TradingRevenueForecastStreamDetailPage,
} from '../components/pages/Trading/RevenueForecastStream/ViewPage';
import { MasterMasterClientPage } from '../components/pages/Master/MasterClient/IndexPage';
import {
  MasterMasterClientNewPage,
  MasterMasterClientDetailPage,
} from '../components/pages/Master/MasterClient/ViewPage';
import { MasterMasterCompanyPage } from '../components/pages/Master/MasterCompany/IndexPage';
import {
  MasterMasterCompanyNewPage,
  MasterMasterCompanyDetailPage,
} from '../components/pages/Master/MasterCompany/ViewPage';
import { MasterMasterMediaTypePage } from '../components/pages/Master/MasterMediaType/IndexPage';
import {
  MasterMasterMediaTypeNewPage,
  MasterMasterMediaTypeDetailPage,
} from '../components/pages/Master/MasterMediaType/ViewPage';
import { MasterRevenueStreamPage } from '../components/pages/Master/RevenueStream/IndexPage';
import {
  MasterRevenueStreamNewPage,
  MasterRevenueStreamDetailPage,
} from '../components/pages/Master/RevenueStream/ViewPage';
import { MasterMediaVendorPage } from '../components/pages/Master/MediaVendor/IndexPage';
import { MasterMediaVendorNewPage, MasterMediaVendorDetailPage } from '../components/pages/Master/MediaVendor/ViewPage';
import { MasterMasterVendorPage } from '../components/pages/Master/MasterVendor/IndexPage';
import {
  MasterMasterVendorNewPage,
  MasterMasterVendorDetailPage,
} from '../components/pages/Master/MasterVendor/ViewPage';
import { MasterMediaOwnerPage } from '../components/pages/Master/MediaOwner/IndexPage';
import { MasterMediaOwnerNewPage, MasterMediaOwnerDetailPage } from '../components/pages/Master/MediaOwner/ViewPage';
import { MasterMasterMediaOwnerPage } from '../components/pages/Master/MasterMediaOwner/IndexPage';
import {
  MasterMasterMediaOwnerNewPage,
  MasterMasterMediaOwnerDetailPage,
} from '../components/pages/Master/MasterMediaOwner/ViewPage';
import { MasterLanguagePage } from '../components/pages/Master/Language/IndexPage';
import { MasterLanguageNewPage, MasterLanguageDetailPage } from '../components/pages/Master/Language/ViewPage';
import { MasterCurrencyPage } from '../components/pages/Master/Currency/IndexPage';
import { MasterCurrencyNewPage, MasterCurrencyDetailPage } from '../components/pages/Master/Currency/ViewPage';
import { TradingForecastBreakPage } from '../components/pages/Trading/ForecastBreak/IndexPage';
import {
  TradingForecastBreakNewPage,
  TradingForecastBreakDetailPage,
} from '../components/pages/Trading/ForecastBreak/ViewPage';
import { TradingForecastAuditTrailPage } from '../components/pages/Trading/ForecastAuditTrail/IndexPage';
import {
  TradingForecastAuditTrailNewPage,
  TradingForecastAuditTrailDetailPage,
} from '../components/pages/Trading/ForecastAuditTrail/ViewPage';
import { TradingClientProfileSetupPage } from '../components/pages/Trading/ClientProfileSetup/IndexPage';
import { TradingBilledSpendPage } from '../components/pages/Trading/BilledSpend/IndexPage';
import {
  TradingBilledSpendNewPage,
  TradingBilledSpendDetailPage,
} from '../components/pages/Trading/BilledSpend/ViewPage';
import { TradingOrderedSpendPage } from '../components/pages/Trading/OrderedSpend/IndexPage';
import { TradingSpendsSetPage } from '../components/pages/Trading/SpendsSet/IndexPage';
import { TradingSpendsSetNewPage, TradingSpendsSetDetailPage } from '../components/pages/Trading/SpendsSet/ViewPage';
import { TradingSACLinesPage } from '../components/pages/Trading/SACLines/IndexPage';
import { TradingActualContributionPage } from '../components/pages/Trading/ActualContribution/IndexPage';
import {
  TradingActualContributionNewPage,
  TradingActualContributionDetailPage,
} from '../components/pages/Trading/ActualContribution/ViewPage';

export const createRoutes = (isAuthenticated) => (
  <>
    <Route path="/Master/Client/Index" element={isAuthenticated ? <MasterClientPage /> : <Navigate to="/" />} />
    <Route path="/Master/Client/New" element={isAuthenticated ? <MasterClientNewPage /> : <Navigate to="/" />} />
    <Route path="/Master/Client/:id" element={isAuthenticated ? <MasterClientDetailPage /> : <Navigate to="/" />} />
    <Route path="/Master/Company/Index" element={isAuthenticated ? <MasterCompanyPage /> : <Navigate to="/" />} />
    <Route path="/Master/Company/New" element={isAuthenticated ? <MasterCompanyNewPage /> : <Navigate to="/" />} />
    <Route path="/Master/Company/:id" element={isAuthenticated ? <MasterCompanyDetailPage /> : <Navigate to="/" />} />
    <Route path="/Master/ClientSet/Index" element={isAuthenticated ? <MasterClientSetPage /> : <Navigate to="/" />} />
    <Route path="/Master/ClientSet/New" element={isAuthenticated ? <MasterClientSetNewPage /> : <Navigate to="/" />} />
    <Route
      path="/Master/ClientSet/:id"
      element={isAuthenticated ? <MasterClientSetDetailPage /> : <Navigate to="/" />}
    />
    <Route path="/Master/Market/Index" element={isAuthenticated ? <MasterMarketPage /> : <Navigate to="/" />} />
    <Route path="/Master/Market/New" element={isAuthenticated ? <MasterMarketNewPage /> : <Navigate to="/" />} />
    <Route path="/Master/Market/:id" element={isAuthenticated ? <MasterMarketDetailPage /> : <Navigate to="/" />} />
    <Route path="/Master/MediaType/Index" element={isAuthenticated ? <MasterMediaTypePage /> : <Navigate to="/" />} />
    <Route path="/Master/MediaType/New" element={isAuthenticated ? <MasterMediaTypeNewPage /> : <Navigate to="/" />} />
    <Route
      path="/Master/MediaType/:id"
      element={isAuthenticated ? <MasterMediaTypeDetailPage /> : <Navigate to="/" />}
    />
    <Route path="/Master/SaleHouse/Index" element={isAuthenticated ? <MasterSaleHousePage /> : <Navigate to="/" />} />
    <Route path="/Master/SaleHouse/New" element={isAuthenticated ? <MasterSaleHouseNewPage /> : <Navigate to="/" />} />
    <Route
      path="/Master/SaleHouse/:id"
      element={isAuthenticated ? <MasterSaleHouseDetailPage /> : <Navigate to="/" />}
    />
    <Route path="/Master/Region/Index" element={isAuthenticated ? <MasterRegionPage /> : <Navigate to="/" />} />
    <Route path="/Master/Region/New" element={isAuthenticated ? <MasterRegionNewPage /> : <Navigate to="/" />} />
    <Route path="/Master/Region/:id" element={isAuthenticated ? <MasterRegionDetailPage /> : <Navigate to="/" />} />
    <Route
      path="/Trading/AgencyProfile/Index"
      element={isAuthenticated ? <TradingAgencyProfilePage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AgencyProfile/New"
      element={isAuthenticated ? <TradingAgencyProfileNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AgencyProfile/:id"
      element={isAuthenticated ? <TradingAgencyProfileDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ClientProfile/Index"
      element={isAuthenticated ? <TradingClientProfilePage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ClientProfile/New"
      element={isAuthenticated ? <TradingClientProfileNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ClientProfile/:id"
      element={isAuthenticated ? <TradingClientProfileDetailPage /> : <Navigate to="/" />}
    />
    <Route path="/Trading/Deal/Index" element={isAuthenticated ? <TradingDealPage /> : <Navigate to="/" />} />
    <Route path="/Trading/Deal/New" element={isAuthenticated ? <TradingDealNewPage /> : <Navigate to="/" />} />
    <Route path="/Trading/Deal/:id" element={isAuthenticated ? <TradingDealDetailPage /> : <Navigate to="/" />} />
    <Route path="/Trading/SACConfig/Index" element={isAuthenticated ? <TradingSACConfigPage /> : <Navigate to="/" />} />
    <Route
      path="/Trading/SACConfig/New"
      element={isAuthenticated ? <TradingSACConfigNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/SACConfig/:id"
      element={isAuthenticated ? <TradingSACConfigDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/RevenueForecastStream/Index"
      element={isAuthenticated ? <TradingRevenueForecastStreamPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/RevenueForecastStream/New"
      element={isAuthenticated ? <TradingRevenueForecastStreamNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/RevenueForecastStream/:id"
      element={isAuthenticated ? <TradingRevenueForecastStreamDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterClient/Index"
      element={isAuthenticated ? <MasterMasterClientPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterClient/New"
      element={isAuthenticated ? <MasterMasterClientNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterClient/:id"
      element={isAuthenticated ? <MasterMasterClientDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterCompany/Index"
      element={isAuthenticated ? <MasterMasterCompanyPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterCompany/New"
      element={isAuthenticated ? <MasterMasterCompanyNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterCompany/:id"
      element={isAuthenticated ? <MasterMasterCompanyDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterMediaType/Index"
      element={isAuthenticated ? <MasterMasterMediaTypePage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterMediaType/New"
      element={isAuthenticated ? <MasterMasterMediaTypeNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterMediaType/:id"
      element={isAuthenticated ? <MasterMasterMediaTypeDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/RevenueStream/Index"
      element={isAuthenticated ? <MasterRevenueStreamPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/RevenueStream/New"
      element={isAuthenticated ? <MasterRevenueStreamNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/RevenueStream/:id"
      element={isAuthenticated ? <MasterRevenueStreamDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MediaVendor/Index"
      element={isAuthenticated ? <MasterMediaVendorPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MediaVendor/New"
      element={isAuthenticated ? <MasterMediaVendorNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MediaVendor/:id"
      element={isAuthenticated ? <MasterMediaVendorDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterVendor/Index"
      element={isAuthenticated ? <MasterMasterVendorPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterVendor/New"
      element={isAuthenticated ? <MasterMasterVendorNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterVendor/:id"
      element={isAuthenticated ? <MasterMasterVendorDetailPage /> : <Navigate to="/" />}
    />
    <Route path="/Master/MediaOwner/Index" element={isAuthenticated ? <MasterMediaOwnerPage /> : <Navigate to="/" />} />
    <Route
      path="/Master/MediaOwner/New"
      element={isAuthenticated ? <MasterMediaOwnerNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MediaOwner/:id"
      element={isAuthenticated ? <MasterMediaOwnerDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterMediaOwner/Index"
      element={isAuthenticated ? <MasterMasterMediaOwnerPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterMediaOwner/New"
      element={isAuthenticated ? <MasterMasterMediaOwnerNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Master/MasterMediaOwner/:id"
      element={isAuthenticated ? <MasterMasterMediaOwnerDetailPage /> : <Navigate to="/" />}
    />
    <Route path="/Master/Language/Index" element={isAuthenticated ? <MasterLanguagePage /> : <Navigate to="/" />} />
    <Route path="/Master/Language/New" element={isAuthenticated ? <MasterLanguageNewPage /> : <Navigate to="/" />} />
    <Route path="/Master/Language/:id" element={isAuthenticated ? <MasterLanguageDetailPage /> : <Navigate to="/" />} />
    <Route path="/Master/Currency/Index" element={isAuthenticated ? <MasterCurrencyPage /> : <Navigate to="/" />} />
    <Route path="/Master/Currency/New" element={isAuthenticated ? <MasterCurrencyNewPage /> : <Navigate to="/" />} />
    <Route path="/Master/Currency/:id" element={isAuthenticated ? <MasterCurrencyDetailPage /> : <Navigate to="/" />} />
    <Route
      path="/Trading/ForecastBreak/Index"
      element={isAuthenticated ? <TradingForecastBreakPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ForecastBreak/New"
      element={isAuthenticated ? <TradingForecastBreakNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ForecastBreak/:id"
      element={isAuthenticated ? <TradingForecastBreakDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ForecastAuditTrail/Index"
      element={isAuthenticated ? <TradingForecastAuditTrailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ForecastAuditTrail/New"
      element={isAuthenticated ? <TradingForecastAuditTrailNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ForecastAuditTrail/:id"
      element={isAuthenticated ? <TradingForecastAuditTrailDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ClientProfileSetup/Index"
      element={isAuthenticated ? <TradingClientProfileSetupPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/BilledSpend/Index"
      element={isAuthenticated ? <TradingBilledSpendPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/BilledSpend/New"
      element={isAuthenticated ? <TradingBilledSpendNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/BilledSpend/:id"
      element={isAuthenticated ? <TradingBilledSpendDetailPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/OrderedSpend/Index"
      element={isAuthenticated ? <TradingOrderedSpendPage /> : <Navigate to="/" />}
    />

    <Route path="/Trading/SpendsSet/Index" element={isAuthenticated ? <TradingSpendsSetPage /> : <Navigate to="/" />} />
    <Route
      path="/Trading/SpendsSet/New"
      element={isAuthenticated ? <TradingSpendsSetNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/SpendsSet/:id"
      element={isAuthenticated ? <TradingSpendsSetDetailPage /> : <Navigate to="/" />}
    />
    <Route path="/Trading/SACLines/Index" element={isAuthenticated ? <TradingSACLinesPage /> : <Navigate to="/" />} />

    <Route
      path="/Trading/ActualContribution/Index"
      element={isAuthenticated ? <TradingActualContributionPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ActualContribution/New"
      element={isAuthenticated ? <TradingActualContributionNewPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/ActualContribution/:id"
      element={isAuthenticated ? <TradingActualContributionDetailPage /> : <Navigate to="/" />}
    />
  </>
);
