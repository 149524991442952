import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScriptsCallback, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
import { getEntityDetails } from '../../../../services/ApiServices';

export function IntegrationDefinitionNewPage(): React.ReactElement {
  const [state] = useState<IIntegrationDefinition>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Integrator', 'IntegrationDefinition');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Admin/Scripts/Resources/en/res.Integrator.en.js',
          'Areas/Admin/Scripts/Resources/en/res.IntegrationDefinition.en.js',
          'Areas/Admin/Scripts/Resources/en/Types.en.js',
          'Areas/Admin/Scripts/Enums.js',
          'Areas/Admin/Scripts/IntegrationDefinition/View.js',
        ],
        'initNewIntegratorIntegrationDefinition',
        { viewmodel: state, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="IntegrationDefinition" className="controls-container default block">
              <h3>
                Create a New <b>Integration Definition</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="IntegrationDefinitionCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="IntegrationDefinitionLabelBlockedCode">Blocked</label>
                  <input
                    id="IntegrationDefinitionCheckBlockedCode"
                    defaultChecked
                    name="IntegrationDefinitionCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 shortcontrol-container">
                  <label htmlFor="Alias">Alias</label>
                  <input
                    data-required-msg="Alias is required"
                    id="IntegrationDefinitionAlias"
                    max="100"
                    min="1"
                    name="Alias"
                    required
                    type="text"
                    defaultValue=""
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 shortcontrol-container">
                  <label htmlFor="Source">Source</label>
                  <input
                    data-required-msg="Source is required"
                    id="IntegrationDefinitionSource"
                    max="100"
                    min="1"
                    name="Source"
                    required
                    type="text"
                    defaultValue=""
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 shortcontrol-container combobox ">
                  <label htmlFor="BoundedContext">Bounded Context</label>
                  <select
                    className=""
                    id="IntegrationDefinitionBoundedContext"
                    name="BoundedContext"
                    required
                    data-required-msg="Bounded Context is required"
                  ></select>
                </div>
                <div className="col-md-4 shortcontrol-container combobox ">
                  <label htmlFor="Entity">Entity</label>
                  <select
                    className=""
                    id="IntegrationDefinitionEntity"
                    name="Entity"
                    required
                    data-required-msg="Entity is required"
                  ></select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2 xshortcontrol-container dropdownlist  ">
                  <label htmlFor="Type">Mapping Type</label>
                  <select
                    className=""
                    id="IntegrationDefinitionType"
                    name="Type"
                    required
                    data-required-msg="Mapping Type is required"
                  >
                    <option value="0">Master</option>
                    <option value="1">Forecast Spend</option>
                    <option value="2">Actual Spend</option>
                    <option value="3">Billed Spend</option>
                    <option value="4">Purging</option>
                    <option value="5">Audience</option>
                    <option value="6">Batch</option>
                    <option value="7">Propage Batch</option>
                    <option value="8">Campaigns</option>
                    <option value="9">Trading</option>
                    <option value="10">Others</option>
                  </select>
                </div>
                <div className="col-md-2 xshortcontrol-container dropdownlist  ">
                  <label htmlFor="Mode">Mode</label>
                  <select
                    className=""
                    id="IntegrationDefinitionMode"
                    name="Mode"
                    required
                    data-required-msg="Mode is required"
                  >
                    <option value="0">Online</option>
                    <option value="1">Batch</option>
                    <option value="2">On Demand</option>
                    <option value="99">Unknown</option>
                  </select>
                </div>
                <div className="col-md-2 xshortcontrol-container dropdownlist  ">
                  <label htmlFor="Status">Status</label>
                  <select
                    className=""
                    id="IntegrationDefinitionStatus"
                    name="Status"
                    required
                    data-required-msg="Status is required"
                  >
                    <option value="0">Running</option>
                    <option value="1">Stopped</option>
                  </select>
                </div>
                <div className="col-md-2 xshortcontrol-container dropdownlist  ">
                  <label htmlFor="ContentType">Content Type</label>
                  <select
                    className=""
                    id="IntegrationDefinitionContentType"
                    name="ContentType"
                    required
                    data-required-msg="Content Type is required"
                  >
                    <option value="0">XML</option>
                    <option value="1">JSON</option>
                    <option value="2">CSV</option>
                    <option value="99">Unknown</option>
                  </select>
                </div>
                <div className="col-md-2">
                  <label htmlFor="Order">Order</label>
                  <input
                    required
                    data-required-msg="Order is required"
                    data-val-number="The field Order must be a number."
                    id="IntegrationDefinitionOrder"
                    name="Order"
                    type="text"
                    defaultValue={state.Order}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="field textareafield">
                  <label htmlFor="Description">Description</label>
                  <div style={{ display: 'inline-block' }}>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="IntegrationDefinitionDescription"
                      name="Description"
                      maxLength={2147483647}
                      rows={5}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="IntegrationDefinitionSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarIntegrationDefinition">
                <div className="left">
                  <button
                    id="cancelIntegrationDefinitionButton"
                    type="button"
                    className="k-button button"
                    title="Cancel"
                  >
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveIntegrationDefinitionButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

export interface IIntegrationDefinition {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: string;
    Source?: string;
  };
  DocumentId?: string;
  DocumentDisplayValue?: string;
  Alias?: string;
  BoundedContext?: string;
  ContentType?: string;
  Description?: string;
  Entity?: string;
  Mode?: string;
  Order?: number;
  Source?: string;
  Status?: string;
  Type?: string;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

export function IntegrationDefinitionDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IIntegrationDefinition>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Integrator', 'IntegrationDefinition');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Integrator', 'IntegrationDefinition', id).then((result) => {
        setState(result.Value[0] as IIntegrationDefinition);
        appendScriptsCallback(
          [
            'Areas/Admin/Scripts/Resources/en/res.Integrator.en.js',
            'Areas/Admin/Scripts/Resources/en/res.IntegrationDefinition.en.js',
            'Areas/Admin/Scripts/Resources/en/Types.en.js',
            'Areas/Admin/Scripts/Enums.js',
            'Areas/Admin/Scripts/IntegrationDefinition/View.js',
            'Areas/Admin/Scripts/Resources/en/res.IntegrationField.en.js',
            'Areas/Admin/Scripts/IntegrationField/View.js',
            'Areas/Admin/Scripts/Resources/en/res.IntegrationTransformation.en.js',
            'Areas/Admin/Scripts/IntegrationTransformation/View.js',
            'Areas/Admin/Scripts/Resources/en/res.IntegrationValidation.en.js',
            'Areas/Admin/Scripts/IntegrationValidation/View.js',
          ],
          'initDetailIntegratorIntegrationDefinition',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadIntegratorIntegrationDefinitionState'] = (data) => {
    setState(data.Value[0] as IIntegrationDefinition);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="IntegrationDefinition" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      IntegrationDefinition: <span>{state.Display}</span>
                      <span
                        id="IntegrationDefinitionToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="IntegrationDefinitionCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="IntegrationDefinitionLabelBlockedCode">Blocked</label>
                  <input
                    id="IntegrationDefinitionCheckBlockedCode"
                    defaultChecked
                    name="IntegrationDefinitionCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 shortcontrol-container">
                  <label htmlFor="Alias">Alias</label>
                  <input
                    data-required-msg="Alias is required"
                    id="IntegrationDefinitionAlias"
                    max="100"
                    min="1"
                    name="Alias"
                    required
                    type="text"
                    defaultValue={state.Alias}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 shortcontrol-container">
                  <label htmlFor="Source">Source</label>
                  <input
                    data-required-msg="Source is required"
                    id="IntegrationDefinitionSource"
                    max="100"
                    min="1"
                    name="Source"
                    required
                    type="text"
                    defaultValue={state.Source}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 shortcontrol-container combobox ">
                  <label htmlFor="BoundedContext">Bounded Context</label>
                  <select
                    className=""
                    id="IntegrationDefinitionBoundedContext"
                    name="BoundedContext"
                    required
                    data-required-msg="Bounded Context is required"
                    defaultValue={state.BoundedContext}
                  >
                    {state.BoundedContext && <option value={state.BoundedContext}>{state.BoundedContext}</option>}
                  </select>
                </div>
                <div className="col-md-4 shortcontrol-container combobox ">
                  <label htmlFor="Entity">Entity</label>
                  <select
                    className=""
                    id="IntegrationDefinitionEntity"
                    name="Entity"
                    required
                    data-required-msg="Entity is required"
                    defaultValue={state.Entity}
                  >
                    {state.Entity && <option value={state.Entity}>{state.Entity}</option>}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2 xshortcontrol-container dropdownlist  ">
                  <label htmlFor="Type">Mapping Type</label>
                  <select
                    className=""
                    id="IntegrationDefinitionType"
                    name="Type"
                    value={state.Type}
                    required
                    data-required-msg="Mapping Type is required"
                  >
                    <option value="0">Master</option>
                    <option value="1">Forecast Spend</option>
                    <option value="2">Actual Spend</option>
                    <option value="3">Billed Spend</option>
                    <option value="4">Purging</option>
                    <option value="5">Audience</option>
                    <option value="6">Batch</option>
                    <option value="7">Propage Batch</option>
                    <option value="8">Campaigns</option>
                    <option value="9">Trading</option>
                    <option value="10">Others</option>
                  </select>
                </div>
                <div className="col-md-2 xshortcontrol-container dropdownlist  ">
                  <label htmlFor="Mode">Mode</label>
                  <select
                    className=""
                    id="IntegrationDefinitionMode"
                    name="Mode"
                    value={state.Mode}
                    required
                    data-required-msg="Mode is required"
                  >
                    <option value="0">Online</option>
                    <option value="1">Batch</option>
                    <option value="2">On Demand</option>
                    <option value="99">Unknown</option>
                  </select>
                </div>
                <div className="col-md-2 xshortcontrol-container dropdownlist  ">
                  <label htmlFor="Status">Status</label>
                  <select
                    className=""
                    id="IntegrationDefinitionStatus"
                    name="Status"
                    value={state.Status}
                    required
                    data-required-msg="Status is required"
                  >
                    <option value="0">Running</option>
                    <option value="1">Stopped</option>
                  </select>
                </div>
                <div className="col-md-2 xshortcontrol-container dropdownlist  ">
                  <label htmlFor="ContentType">Content Type</label>
                  <select
                    className=""
                    id="IntegrationDefinitionContentType"
                    name="ContentType"
                    value={state.ContentType}
                    required
                    data-required-msg="Content Type is required"
                  >
                    <option value="0">XML</option>
                    <option value="1">JSON</option>
                    <option value="2">CSV</option>
                    <option value="99">Unknown</option>
                  </select>
                </div>
                <div className="col-md-2">
                  <label htmlFor="Order">Order</label>
                  <input
                    required
                    data-required-msg="Order is required"
                    data-val-number="The field Order must be a number."
                    id="IntegrationDefinitionOrder"
                    name="Order"
                    type="text"
                    defaultValue={state.Order}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="field textareafield">
                  <label htmlFor="Description">Description</label>
                  <div style={{ display: 'inline-block' }}>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="IntegrationDefinitionDescription"
                      defaultValue={state.Description}
                      name="Description"
                      maxLength={2147483647}
                      rows={5}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="IntegrationDefinitionSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="IntegrationDefinitionSourceStatus"
                    name="SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select
                    className=""
                    id="IntegrationDefinitionLoadingInfoSource"
                    name="Source"
                    value={state.LoadingInfo.Source}
                  >
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarIntegrationDefinition">
                <div className="left">
                  <button
                    id="cancelIntegrationDefinitionButton"
                    type="button"
                    className="k-button button"
                    title="Cancel"
                  >
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveIntegrationDefinitionButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <div id="IntegrationDefinitionTabstrip">
            <ul>
              <li id="IntegrationDefinitionIntegrationField" className="k-state-active">
                Fields
              </li>
              <li id="IntegrationDefinitionIntegrationTransformation">Transformations</li>
              <li id="IntegrationDefinitionIntegrationValidation">Validations</li>
            </ul>
            <div>
              <div id="associationIntegrationDefinitionIntegrationField">
                <div className="k-block grid-details">
                  <h2>Fields</h2>
                  <div id="IntegrationFieldGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
            <div>
              <div id="associationIntegrationDefinitionIntegrationTransformation">
                <div className="k-block grid-details">
                  <h2>Transformations</h2>
                  <div id="IntegrationTransformationGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
            <div>
              <div id="associationIntegrationDefinitionIntegrationValidation">
                <div className="k-block grid-details">
                  <h2>Validations</h2>
                  <div id="IntegrationValidationGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
