import React from 'react';
import { useSecurity } from '../../helpers/SecurityHooks';
import { appendScripts } from '../../helpers/Utils';

export function RevenueForecastStreamUploadPage(): React.ReactElement {
  const currentSecurity = useSecurity('Trading', 'AgencyProfile');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScripts(['Areas/Trading/Scripts/RevenueForecastStream/RevenueForecastStreamUpload.custom.js']);
    }
  }, []);

  return (
    <div id="UploadContent" className="container-fluid">
      <form id="form" className="content-view row">
        <h2 style={{ paddingTop: '1em' }}>Forecast Adjustment Upload</h2>
        <div className="clear"></div>
        <p style={{ fontSize: "1.3REM" }}>
          Agency Billing / Revenue Adjustments &gt; Used by Agencies to manually adjust their Billing or Revenue figures:
          <div className="clear" style={{ marginLeft: "50px", marginTop: "20px" }}>
            Manual Adjustment Steps:
            <div className="clear" style={{ marginLeft: "30px", marginTop: "15px", fontSize: "1.1REM" }}>
              1. Navigate to <span style={{ fontWeight: "600" }}>"Trading Reporting (R)" &gt; "Excel Reports"</span> and generate <span style={{ fontWeight: "600" }}>"Manual Adjustment"</span> report for the specific period / agency you want to apply manual adjustments to.
              <div className="clear" style={{ marginLeft: "50px", marginTop: "10px", fontSize: "1REM" }}>
                a. This report will contain all current Billing/Revenue values as well as any previously updated manual adjustments.
              </div>
            </div>
            <div className="clear" style={{ marginLeft: "30px", marginTop: "15px", fontSize: "1.1REM" }}>  
              2. To manually adjust a line item, please use the "Adjust_Cost_Amount" column to do so, once entered the 'adjusted' value will become the new value for reporting purposes.
              <div className="clear" style={{ marginLeft: "50px", marginTop: "10px", fontSize: "1REM" }}>
                a. If an "Adjust_Cost_Amount" column contains a value, and you would like to revert to the calculated value, just remove and leave the cell empty.
              </div>
              <div className="clear" style={{ marginLeft: "50px", marginTop: "10px", fontSize: "1REM" }}>
                b. <span style={{ color: "red" }}>** IMPORTANT:</span> If there are OP and AC fees for same line item you are looking to create a manual adjustment for, both line items must be updated otherwise the value not updated will remain as a calculated value
              </div>

            </div>
          </div>
          <div className="clear" style={{ marginLeft: "50px", marginTop: "20px" }}>
          Upload Steps:
            <div className="clear" style={{ marginLeft: "30px", marginTop: "15px", fontSize: "1.1REM" }}>
              1. Please select the files to be uploaded by clicking on the <span style={{ fontWeight: "600" }}>"Select File"</span> option. Once ready select <span style={{ fontWeight: "600" }}>"Upload"</span> and wait until the process has completed.
          </div>
          </div>
        </p>
        <div className="clear"></div>
        <section className="button-toolbar" id="ToolbarAnnualBudget">
          <div className="right">
            <button id="editAnnualBudgetButton" type="button" className="k-button button" title="Select File">
              Select File
            </button>
          </div>
        </section>
      </form>
    </div>
  );
}
