import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function TradingBilledSpendNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingBilledSpend>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'BilledSpend');
  const updatedState: ITradingBilledSpend = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      updatedState.Year = new Date().getFullYear().toString();
      updatedState.MOA = new Date().toString();
      updatedState.MOS = new Date().toString();
      updatedState.GrossSpend = 0;
      updatedState.NetSpend = 0;
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.BilledSpend.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/BilledSpend/View.js',
        ],
        'initNewTradingBilledSpend',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="BilledSpend" className="controls-container default block">
              <h3>
                Create a New <b>Billed Spends</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="BilledSpendCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="BilledSpendLabelBlockedCode">Blocked</label>
                  <input
                    id="BilledSpendCheckBlockedCode"
                    defaultChecked
                    name="BilledSpendCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="ClientId">Client</label>
                  <select className="" id="BilledSpendClientId" name="ClientId" defaultValue={state.ClientId}>
                    {state.ClientId && <option value={state.ClientId}>{state.ClientDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="RevenueStreamId">Revenue Stream</label>
                  <select
                    className=""
                    id="BilledSpendRevenueStreamId"
                    name="RevenueStreamId"
                    defaultValue={state.RevenueStreamId}
                    required
                    data-required-msg="Revenue  Stream is required"
                  >
                    {state.RevenueStreamId && (
                      <option value={state.RevenueStreamId}>{state.RevenueStreamDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterClientId">Master Client</label>
                  <select
                    className=""
                    id="BilledSpendMasterClientId"
                    name="MasterClientId"
                    defaultValue={state.MasterClientId}
                    required
                    data-required-msg="Master  Client is required"
                  >
                    {state.MasterClientId && (
                      <option value={state.MasterClientId}>{state.MasterClientDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterCompanyId">Master Company</label>
                  <select
                    className=""
                    id="BilledSpendMasterCompanyId"
                    name="MasterCompanyId"
                    defaultValue={state.MasterCompanyId}
                    required
                    data-required-msg="Master  Company is required"
                  >
                    {state.MasterCompanyId && (
                      <option value={state.MasterCompanyId}>{state.MasterCompanyDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="AgencyId">Agency</label>
                  <select className="" id="BilledSpendAgencyId" name="AgencyId" defaultValue={state.AgencyId}>
                    {state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-2">
                  <label htmlFor="MOA">MOA</label>
                  <input
                    required
                    data-required-msg="MOA is required"
                    id="BilledSpendMOA"
                    name="MOA"
                    type="text"
                    defaultValue={state.MOA}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="MOS">MOS</label>
                  <input
                    required
                    data-required-msg="MOS is required"
                    id="BilledSpendMOS"
                    name="MOS"
                    type="text"
                    defaultValue={state.MOS}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="AGCommission">AGCommission</label>
                  <input
                    required
                    data-required-msg="AGCommission is required"
                    data-val-number="The field AGCommission must be a number."
                    id="BilledSpendAGCommission"
                    name="AGCommission"
                    type="text"
                    defaultValue={state.AGCommission}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="Commission">Commission</label>
                  <input
                    required
                    data-required-msg="Commission is required"
                    data-val-number="The field Commission must be a number."
                    id="BilledSpendCommission"
                    name="Commission"
                    type="text"
                    defaultValue={state.Commission}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="GrossSpend">Gross Spend</label>
                  <input
                    required
                    data-required-msg="Gross Spend is required"
                    data-val-number="The field Gross Spend must be a number."
                    id="BilledSpendGrossSpend"
                    name="GrossSpend"
                    type="text"
                    defaultValue={state.GrossSpend}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="NetSpend">Net Spend</label>
                  <input
                    required
                    data-required-msg="Net Spend is required"
                    data-val-number="The field Net Spend must be a number."
                    id="BilledSpendNetSpend"
                    name="NetSpend"
                    type="text"
                    defaultValue={state.NetSpend}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="Year">Year</label>
                  <input
                    required
                    data-required-msg="Year is required"
                    data-val-number="The field Year must be a number."
                    id="BilledSpendYear"
                    name="Year"
                    type="text"
                    defaultValue={state.Year}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="BilledSpendSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarBilledSpend">
                <div className="left">
                  <button id="cancelBilledSpendButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveBilledSpendButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingBilledSpend {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  ClientId?: string;
  ClientDisplayValue?: string;
  RevenueStreamId?: string;
  RevenueStreamDisplayValue?: string;
  MasterClientId?: string;
  MasterClientDisplayValue?: string;
  MasterCompanyId?: string;
  MasterCompanyDisplayValue?: string;
  AgencyId?: string;
  AgencyDisplayValue?: string;
  MOA?: string;
  MOS?: string;
  AGCommission?: number;
  Commission?: number;
  GrossSpend?: number;
  NetSpend?: number;
  Year?: string;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

export function TradingBilledSpendDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingBilledSpend>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'BilledSpend');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Trading', 'BilledSpend', id).then((result) => {
        setState(result.Value[0] as ITradingBilledSpend);
        appendScriptsCallback(
          [
            'Areas/Trading/Scripts/Resources/en/res.BilledSpend.en.js',
            'Areas/Trading/Scripts/Resources/en/Types.en.js',
            'Areas/Trading/Scripts/Enums.js',
            'Areas/Trading/Scripts/BilledSpend/View.js',
          ],
          'initDetailTradingBilledSpend',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadTradingBilledSpendState'] = (data) => {
    setState(data.Value[0] as ITradingBilledSpend);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="BilledSpend" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Billed Spends: <span>{state.Display}</span>
                      <span
                        id="BilledSpendToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.ClientId && (
                    <span className="element">
                      <label>
                        Client: <label className="strong">{state.ClientDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.RevenueStreamId && (
                    <span className="element">
                      <label>
                        Revenue Stream: <label className="strong">{state.RevenueStreamDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.MasterClientId && (
                    <span className="element">
                      <label>
                        Master Client: <label className="strong">{state.MasterClientDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
                <div className="content-line">
                  {state.MasterCompanyId && (
                    <span className="element">
                      <label>
                        Master Company: <label className="strong">{state.MasterCompanyDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.AgencyId && (
                    <span className="element">
                      <label>
                        Agency: <label className="strong">{state.AgencyDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="BilledSpendCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="BilledSpendLabelBlockedCode">Blocked</label>
                  <input
                    id="BilledSpendCheckBlockedCode"
                    defaultChecked
                    name="BilledSpendCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="ClientId">Client</label>
                  <select className="" id="BilledSpendClientId" name="ClientId" defaultValue={state.ClientId}>
                    {state.ClientId && <option value={state.ClientId}>{state.ClientDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="RevenueStreamId">Revenue Stream</label>
                  <select
                    className=""
                    id="BilledSpendRevenueStreamId"
                    name="RevenueStreamId"
                    defaultValue={state.RevenueStreamId}
                    required
                    data-required-msg="Revenue  Stream is required"
                  >
                    {state.RevenueStreamId && (
                      <option value={state.RevenueStreamId}>{state.RevenueStreamDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterClientId">Master Client</label>
                  <select
                    className=""
                    id="BilledSpendMasterClientId"
                    name="MasterClientId"
                    defaultValue={state.MasterClientId}
                    required
                    data-required-msg="Master  Client is required"
                  >
                    {state.MasterClientId && (
                      <option value={state.MasterClientId}>{state.MasterClientDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterCompanyId">Master Company</label>
                  <select
                    className=""
                    id="BilledSpendMasterCompanyId"
                    name="MasterCompanyId"
                    defaultValue={state.MasterCompanyId}
                    required
                    data-required-msg="Master  Company is required"
                  >
                    {state.MasterCompanyId && (
                      <option value={state.MasterCompanyId}>{state.MasterCompanyDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="AgencyId">Agency</label>
                  <select className="" id="BilledSpendAgencyId" name="AgencyId" defaultValue={state.AgencyId}>
                    {state.AgencyId && <option value={state.AgencyId}>{state.AgencyDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-2">
                  <label htmlFor="MOA">MOA</label>
                  <input
                    required
                    data-required-msg="MOA is required"
                    id="BilledSpendMOA"
                    name="MOA"
                    type="text"
                    defaultValue={state.MOA}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="MOS">MOS</label>
                  <input
                    required
                    data-required-msg="MOS is required"
                    id="BilledSpendMOS"
                    name="MOS"
                    type="text"
                    defaultValue={state.MOS}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="AGCommission">AGCommission</label>
                  <input
                    required
                    data-required-msg="AGCommission is required"
                    data-val-number="The field AGCommission must be a number."
                    id="BilledSpendAGCommission"
                    name="AGCommission"
                    type="text"
                    defaultValue={state.AGCommission}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="Commission">Commission</label>
                  <input
                    required
                    data-required-msg="Commission is required"
                    data-val-number="The field Commission must be a number."
                    id="BilledSpendCommission"
                    name="Commission"
                    type="text"
                    defaultValue={state.Commission}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="GrossSpend">Gross Spend</label>
                  <input
                    required
                    data-required-msg="Gross Spend is required"
                    data-val-number="The field Gross Spend must be a number."
                    id="BilledSpendGrossSpend"
                    name="GrossSpend"
                    type="text"
                    defaultValue={state.GrossSpend}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="NetSpend">Net Spend</label>
                  <input
                    required
                    data-required-msg="Net Spend is required"
                    data-val-number="The field Net Spend must be a number."
                    id="BilledSpendNetSpend"
                    name="NetSpend"
                    type="text"
                    defaultValue={state.NetSpend}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="Year">Year</label>
                  <input
                    required
                    data-required-msg="Year is required"
                    data-val-number="The field Year must be a number."
                    id="BilledSpendYear"
                    name="Year"
                    type="text"
                    defaultValue={state.Year}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="BilledSpendSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="BilledSpendSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select
                    className=""
                    id="BilledSpendSource"
                    name="LoadingInfo.Source"
                    value={state.LoadingInfo.Source}
                  >
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarBilledSpend">
                <div className="left">
                  <button id="cancelBilledSpendButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveBilledSpendButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
