import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
import { getEntityDetails } from '../../../../services/ApiServices';

export function MasterCurrencyNewPage(): React.ReactElement {
  const [state] = useState<IMasterCurrency>({ Id: '' });
  const currentSecurity = useSecurity('Master', 'Currency');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Master/Scripts/Resources/en/res.Currency.en.js',
          'Areas/Master/Scripts/Resources/en/Types.en.js',
          'Areas/Master/Scripts/Enums.js',
          'Areas/Master/Scripts/Currency/View.js',
        ],
        'initNewMasterCurrency',
        { viewmodel: state, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Currency" className="controls-container default block">
              <h3>
                Create a New <b>Currencies</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="CurrencyCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="CurrencyLabelBlockedCode">Blocked</label>
                  <input id="CurrencyCheckBlockedCode" defaultChecked name="CurrencyCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="CurrencyEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() => window['OnChangeReply']('CurrencyEnglishName', 'CurrencyNativeName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="CurrencyNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="CurrencyEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() => window['OnChangeReply']('CurrencyEnglishShortName', 'CurrencyNativeShortName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="CurrencyNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="DisplayFormat">Format(RegEx)</label>
                  <input
                    required
                    data-required-msg="Format(RegEx) is required"
                    id="CurrencyDisplayFormat"
                    name="DisplayFormat"
                    type="text"
                    defaultValue={state.DisplayFormat}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="Preferred">Preferred</label>
                  <input type="checkbox" id="CurrencyPreferred" name="Preferred" defaultChecked={state.Preferred} />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="CurrencySourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarCurrency">
                <div className="left">
                  <button id="cancelCurrencyButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveCurrencyButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMasterCurrency {
  EnglishName?: string;
  EnglishShortName?: string;
  NativeName?: string;
  NativeShortName?: string;
  DisplayFormat?: string;
  Preferred?: boolean;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

export function MasterCurrencyDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMasterCurrency>({ Id: '' });
  const currentSecurity = useSecurity('Master', 'Currency');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Master', 'Currency', id).then((result) => {
        setState(result.Value[0] as IMasterCurrency);
        appendScriptsCallback(
          [
            'Areas/Master/Scripts/Resources/en/res.Currency.en.js',
            'Areas/Master/Scripts/Resources/en/Types.en.js',
            'Areas/Master/Scripts/Enums.js',
            'Areas/Master/Scripts/Currency/View.js',
          ],
          'initDetailMasterCurrency',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadMasterCurrencyState'] = (data) => {
    setState(data.Value[0] as IMasterCurrency);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Currency" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Currencies: <span>{state.Display}</span>
                      <span
                        id="CurrencyToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line"></div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="CurrencyCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="CurrencyLabelBlockedCode">Blocked</label>
                  <input id="CurrencyCheckBlockedCode" defaultChecked name="CurrencyCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="CurrencyEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() => window['OnChangeReply']('CurrencyEnglishName', 'CurrencyNativeName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="CurrencyNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="CurrencyEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() => window['OnChangeReply']('CurrencyEnglishShortName', 'CurrencyNativeShortName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="CurrencyNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="DisplayFormat">Format(RegEx)</label>
                  <input
                    required
                    data-required-msg="Format(RegEx) is required"
                    id="CurrencyDisplayFormat"
                    name="DisplayFormat"
                    type="text"
                    defaultValue={state.DisplayFormat}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="Preferred">Preferred</label>
                  <input type="checkbox" id="CurrencyPreferred" name="Preferred" defaultChecked={state.Preferred} />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="CurrencySourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarCurrency">
                <div className="left">
                  <button id="cancelCurrencyButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveCurrencyButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
