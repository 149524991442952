import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function TradingActualContributionNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingActualContribution>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'ActualContribution');
  const updatedState: ITradingActualContribution = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      updatedState.Contribution = 10;
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.ActualContribution.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/ActualContribution/View.js',
        ],
        'initNewTradingActualContribution',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="ActualContribution" className="controls-container default block">
              <h3>
                Create a New <b>Actual Contribution</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="ActualContributionCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="ActualContributionLabelBlockedCode">Blocked</label>
                  <input
                    id="ActualContributionCheckBlockedCode"
                    defaultChecked
                    name="ActualContributionCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="ClientProfilePeriodId">Client Profile Period</label>
                  <select
                    className=""
                    id="ActualContributionClientProfilePeriodId"
                    name="ClientProfilePeriodId"
                    defaultValue={state.ClientProfilePeriodId}
                    required
                    data-required-msg="Client  Profile  Period is required"
                  >
                    {state.ClientProfilePeriodId && (
                      <option value={state.ClientProfilePeriodId}>{state.ClientProfilePeriodDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterVendorId">Master Vendor</label>
                  <select
                    className=""
                    id="ActualContributionMasterVendorId"
                    name="MasterVendorId"
                    defaultValue={state.MasterVendorId}
                    required
                    data-required-msg="Master  Vendor is required"
                  >
                    {state.MasterVendorId && (
                      <option value={state.MasterVendorId}>{state.MasterVendorDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaTypeId">Media Type</label>
                  <select
                    className=""
                    id="ActualContributionMediaTypeId"
                    name="MediaTypeId"
                    defaultValue={state.MediaTypeId}
                    required
                    data-required-msg="Media  Type is required"
                  >
                    {state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="SaleHouseId">Sale House</label>
                  <select
                    className=""
                    id="ActualContributionSaleHouseId"
                    name="SaleHouseId"
                    defaultValue={state.SaleHouseId}
                    required
                    data-required-msg="Sale  House is required"
                  >
                    {state.SaleHouseId && <option value={state.SaleHouseId}>{state.SaleHouseDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-2">
                  <label htmlFor="Contribution">Contribution</label>
                  <input
                    required
                    data-required-msg="Contribution is required"
                    data-val-number="The field Contribution must be a number."
                    id="ActualContributionContribution"
                    name="Contribution"
                    type="text"
                    defaultValue={state.Contribution}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="ActualContributionSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarActualContribution">
                <div className="left">
                  <button id="cancelActualContributionButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveActualContributionButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingActualContribution {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  ClientProfilePeriodId?: string;
  ClientProfilePeriodDisplayValue?: string;
  MasterVendorId?: string;
  MasterVendorDisplayValue?: string;
  MediaTypeId?: string;
  MediaTypeDisplayValue?: string;
  SaleHouseId?: string;
  SaleHouseDisplayValue?: string;
  Contribution?: number;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

export function TradingActualContributionDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingActualContribution>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'ActualContribution');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Trading', 'ActualContribution', id).then((result) => {
        setState(result.Value[0] as ITradingActualContribution);
        appendScriptsCallback(
          [
            'Areas/Trading/Scripts/Resources/en/res.ActualContribution.en.js',
            'Areas/Trading/Scripts/Resources/en/Types.en.js',
            'Areas/Trading/Scripts/Enums.js',
            'Areas/Trading/Scripts/ActualContribution/View.js',
          ],
          'initDetailTradingActualContribution',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadTradingActualContributionState'] = (data) => {
    setState(data.Value[0] as ITradingActualContribution);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="ActualContribution" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Actual Contribution: <span>{state.Display}</span>
                      <span
                        id="ActualContributionToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.ClientProfilePeriodId && (
                    <span className="element">
                      <label>
                        Client Profile Period: <label className="strong">{state.ClientProfilePeriodDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.MasterVendorId && (
                    <span className="element">
                      <label>
                        Master Vendor: <label className="strong">{state.MasterVendorDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.MediaTypeId && (
                    <span className="element">
                      <label>
                        Media Type: <label className="strong">{state.MediaTypeDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
                <div className="content-line">
                  {state.SaleHouseId && (
                    <span className="element">
                      <label>
                        Sale House: <label className="strong">{state.SaleHouseDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="ActualContributionCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="ActualContributionLabelBlockedCode">Blocked</label>
                  <input
                    id="ActualContributionCheckBlockedCode"
                    defaultChecked
                    name="ActualContributionCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="ClientProfilePeriodId">Client Profile Period</label>
                  <select
                    className=""
                    id="ActualContributionClientProfilePeriodId"
                    name="ClientProfilePeriodId"
                    defaultValue={state.ClientProfilePeriodId}
                    required
                    data-required-msg="Client  Profile  Period is required"
                  >
                    {state.ClientProfilePeriodId && (
                      <option value={state.ClientProfilePeriodId}>{state.ClientProfilePeriodDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterVendorId">Master Vendor</label>
                  <select
                    className=""
                    id="ActualContributionMasterVendorId"
                    name="MasterVendorId"
                    defaultValue={state.MasterVendorId}
                    required
                    data-required-msg="Master  Vendor is required"
                  >
                    {state.MasterVendorId && (
                      <option value={state.MasterVendorId}>{state.MasterVendorDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaTypeId">Media Type</label>
                  <select
                    className=""
                    id="ActualContributionMediaTypeId"
                    name="MediaTypeId"
                    defaultValue={state.MediaTypeId}
                    required
                    data-required-msg="Media  Type is required"
                  >
                    {state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="SaleHouseId">Sale House</label>
                  <select
                    className=""
                    id="ActualContributionSaleHouseId"
                    name="SaleHouseId"
                    defaultValue={state.SaleHouseId}
                    required
                    data-required-msg="Sale  House is required"
                  >
                    {state.SaleHouseId && <option value={state.SaleHouseId}>{state.SaleHouseDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-2">
                  <label htmlFor="Contribution">Contribution</label>
                  <input
                    required
                    data-required-msg="Contribution is required"
                    data-val-number="The field Contribution must be a number."
                    id="ActualContributionContribution"
                    name="Contribution"
                    type="text"
                    defaultValue={state.Contribution}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="ActualContributionSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="ActualContributionSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select
                    className=""
                    id="ActualContributionSource"
                    name="LoadingInfo.Source"
                    value={state.LoadingInfo.Source}
                  >
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarActualContribution">
                <div className="left">
                  <button id="cancelActualContributionButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveActualContributionButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
