import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function TradingForecastAuditTrailNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingForecastAuditTrail>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'ForecastAuditTrail');
  const updatedState: ITradingForecastAuditTrail = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      updatedState.Status = 0;
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.ForecastAuditTrail.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/ForecastAuditTrail/View.js',
        ],
        'initNewTradingForecastAuditTrail',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="ForecastAuditTrail" className="controls-container default block">
              <h3>
                Create a New <b>Forecast Audit Trail</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="ForecastAuditTrailCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="ForecastAuditTrailLabelBlockedCode">Blocked</label>
                  <input
                    id="ForecastAuditTrailCheckBlockedCode"
                    defaultChecked
                    name="ForecastAuditTrailCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="Action">Action</label>
                  <input
                    required
                    data-required-msg="Action is required"
                    id="ForecastAuditTrailAction"
                    name="Action"
                    type="text"
                    defaultValue={state.Action}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="ClientProfilePeriodId">Client Profile Period</label>
                  <select
                    className=""
                    id="ForecastAuditTrailClientProfilePeriodId"
                    name="ClientProfilePeriodId"
                    defaultValue={state.ClientProfilePeriodId}
                    required
                    data-required-msg="Client  Profile  Period is required"
                  >
                    {state.ClientProfilePeriodId && (
                      <option value={state.ClientProfilePeriodId}>{state.ClientProfilePeriodDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Comments">Comments</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="ForecastAuditTrailComments"
                      name="Comments"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Comments}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Status" className="required">
                    Status
                  </label>
                  <select
                    className=""
                    id="ForecastAuditTrailStatus"
                    name="Status"
                    value={state.Status}
                    required
                    data-required-msg="Status is required"
                  >
                    <option value="0">Open</option>
                    <option value="1">Pending Approval</option>
                    <option value="2">Approved</option>
                    <option value="3">Closed</option>
                    <option value="4">Reopened</option>
                    <option value="5">Pending</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="ForecastAuditTrailSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarForecastAuditTrail">
                <div className="left">
                  <button id="cancelForecastAuditTrailButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveForecastAuditTrailButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingForecastAuditTrail {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  Action?: string;
  ClientProfilePeriodId?: string;
  ClientProfilePeriodDisplayValue?: string;
  Comments?: string;
  Status?: number;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum SubmissionStatusEnum {
  'Open' = 0,
  'Pending Approval' = 1,
  'Approved' = 2,
  'Closed' = 3,
  'Reopened' = 4,
  'Pending' = 5,
}

export function TradingForecastAuditTrailDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingForecastAuditTrail>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'ForecastAuditTrail');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Trading', 'ForecastAuditTrail', id).then((result) => {
        setState(result.Value[0] as ITradingForecastAuditTrail);
        appendScriptsCallback(
          [
            'Areas/Trading/Scripts/Resources/en/res.ForecastAuditTrail.en.js',
            'Areas/Trading/Scripts/Resources/en/Types.en.js',
            'Areas/Trading/Scripts/Enums.js',
            'Areas/Trading/Scripts/ForecastAuditTrail/View.js',
          ],
          'initDetailTradingForecastAuditTrail',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadTradingForecastAuditTrailState'] = (data) => {
    setState(data.Value[0] as ITradingForecastAuditTrail);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="ForecastAuditTrail" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Forecast Audit Trail: <span>{state.Display}</span>
                      <span
                        id="ForecastAuditTrailToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.ClientProfilePeriodId && (
                    <span className="element">
                      <label>
                        Client Profile Period: <label className="strong">{state.ClientProfilePeriodDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="ForecastAuditTrailCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="ForecastAuditTrailLabelBlockedCode">Blocked</label>
                  <input
                    id="ForecastAuditTrailCheckBlockedCode"
                    defaultChecked
                    name="ForecastAuditTrailCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="Action">Action</label>
                  <input
                    required
                    data-required-msg="Action is required"
                    id="ForecastAuditTrailAction"
                    name="Action"
                    type="text"
                    defaultValue={state.Action}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="ClientProfilePeriodId">Client Profile Period</label>
                  <select
                    className=""
                    id="ForecastAuditTrailClientProfilePeriodId"
                    name="ClientProfilePeriodId"
                    defaultValue={state.ClientProfilePeriodId}
                    required
                    data-required-msg="Client  Profile  Period is required"
                  >
                    {state.ClientProfilePeriodId && (
                      <option value={state.ClientProfilePeriodId}>{state.ClientProfilePeriodDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="clear"></div>
                <div className="field textareafield">
                  <label htmlFor="Comments">Comments</label>
                  <div>
                    <textarea
                      className="k-input k-textbox"
                      cols={20}
                      id="ForecastAuditTrailComments"
                      name="Comments"
                      maxLength={2147483647}
                      rows={5}
                      defaultValue={state.Comments}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Status" className="required">
                    Status
                  </label>
                  <select
                    className=""
                    id="ForecastAuditTrailStatus"
                    name="Status"
                    value={state.Status}
                    required
                    data-required-msg="Status is required"
                  >
                    <option value="0">Open</option>
                    <option value="1">Pending Approval</option>
                    <option value="2">Approved</option>
                    <option value="3">Closed</option>
                    <option value="4">Reopened</option>
                    <option value="5">Pending</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="ForecastAuditTrailSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="ForecastAuditTrailSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select
                    className=""
                    id="ForecastAuditTrailSource"
                    name="LoadingInfo.Source"
                    value={state.LoadingInfo.Source}
                  >
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarForecastAuditTrail">
                <div className="left">
                  <button id="cancelForecastAuditTrailButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveForecastAuditTrailButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
