import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function TradingDealCustomNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingDeal>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'Deal');
  const updatedState: ITradingDeal = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      updatedState.DealYear = new Date().getFullYear().toString();
      updatedState.StartDate = new Date().toString();
      updatedState.EndDate = new Date().toString();
      const date = new Date();
      const startDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
      const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 3, -1));
      updatedState.StartDate = startDate.toISOString();
      updatedState.EndDate = endDate.toISOString();
      updatedState.RebateCalculation = 0;
      updatedState.Minimum = 0;
      updatedState.Amount = 0;
      updatedState.Rebate = 0;
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.Deal.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/Deal/View.js',
        ],
        'initNewTradingDeal',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Deal" className="controls-container default block">
              <h3>
                Create a New <b>Deals</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="DealCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="DealLabelBlockedCode">Blocked</label>
                  <input id="DealCheckBlockedCode" defaultChecked name="DealCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterVendorId">Master Vendor</label>
                  <select
                    className=""
                    id="DealMasterVendorId"
                    name="MasterVendorId"
                    defaultValue={state.MasterVendorId}
                    required
                    data-required-msg="Master  Vendor is required"
                  >
                    {state.MasterVendorId && (
                      <option value={state.MasterVendorId}>{state.MasterVendorDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="OrderedSpendsVersionId">Ordered Spends Version</label>
                  <select
                    className=""
                    id="DealOrderedSpendsVersionId"
                    name="OrderedSpendsVersionId"
                    defaultValue={state.OrderedSpendsVersionId}
                    required
                    data-required-msg="Ordered  Spends  Version is required"
                  >
                    {state.OrderedSpendsVersionId && (
                      <option value={state.OrderedSpendsVersionId}>{state.OrderedSpendsVersionDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="Alias">Alias</label>
                  <input
                    required
                    data-required-msg="Alias is required"
                    id="DealAlias"
                    name="Alias"
                    type="text"
                    defaultValue={state.Alias}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="Minimum">Minimum Spending</label>
                  <input
                    required
                    data-required-msg="Minimum Spending is required"
                    data-val-number="The field Minimum Spending must be a number."
                    id="DealMinimum"
                    name="Minimum"
                    type="text"
                    defaultValue={state.Minimum}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="RebateCalculation" className="required">
                    Rebate Calculation
                  </label>
                  <select
                    className=""
                    id="DealRebateCalculation"
                    name="RebateCalculation"
                    value={state.RebateCalculation}
                    required
                    data-required-msg="Rebate Calculation is required"
                  >
                    <option value="0">Fixed</option>
                    <option value="1">Tier</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2">
                  <label htmlFor="StartDate">Start Date</label>
                  <input
                    required
                    data-required-msg="Start Date is required"
                    id="DealStartDate"
                    name="StartDate"
                    type="text"
                    defaultValue={state.StartDate}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="EndDate">End Date</label>
                  <input
                    required
                    data-required-msg="End Date is required"
                    id="DealEndDate"
                    name="EndDate"
                    type="text"
                    defaultValue={state.EndDate}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="DealYear">Deal Year</label>
                  <input
                    required
                    data-required-msg="Deal Year is required"
                    data-val-number="The field Deal Year must be a number."
                    id="DealDealYear"
                    name="DealYear"
                    type="text"
                    defaultValue={state.DealYear}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="DealSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarDeal">
                <div className="left">
                  <button id="cancelDealButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveDealButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingDeal {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  MasterVendorId?: string;
  MasterVendorDisplayValue?: string;
  OrderedSpendsVersionId?: string;
  OrderedSpendsVersionDisplayValue?: string;
  Alias?: string;
  Minimum?: number;
  RebateCalculation?: number;
  StartDate?: string;
  EndDate?: string;
  Amount?: number;
  DealYear?: string;
  Rebate?: number;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum RebateCalculationEnum {
  'Fixed' = 0,
  'Tier' = 1,
}

export function TradingDealDetailCustomPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingDeal>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'Deal');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Trading', 'Deal', id).then((result) => {
        setState(result.Value[0] as ITradingDeal);
        appendScriptsCallback(
          [
            'Areas/Trading/Scripts/Resources/en/res.Deal.en.js',
            'Areas/Trading/Scripts/Resources/en/Types.en.js',
            'Areas/Trading/Scripts/Enums.js',
            'Areas/Trading/Scripts/Deal/View.js',
            'Areas/Trading/Scripts/Resources/en/res.DealCondition.en.js',
            'Areas/Trading/Scripts/DealCondition/View.custom.js',
            'Areas/Trading/Scripts/Resources/en/res.OrderedSpend.en.js',
            'Areas/Trading/Scripts/OrderedSpend/View.custom.js',
            'Areas/Trading/Scripts/Resources/en/res.DealTier.en.js',
            'Areas/Trading/Scripts/DealTier/View.custom.js',
            'Areas/Trading/Scripts/Resources/en/res.DealBreakDown.en.js',
            'Areas/Trading/Scripts/DealBreakDown/View.custom.js',
          ],
          'initDetailTradingDeal',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadTradingDealState'] = (data) => {
    setState(data.Value[0] as ITradingDeal);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Deal" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Deals: <span>{state.Display}</span>
                      <span id="DealToggle" className="k-icon k-i-media-manager" style={{ cursor: 'pointer' }}></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.MasterVendorId && (
                    <span className="element">
                      <label>
                        Master Vendor: <label className="strong">{state.MasterVendorDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.OrderedSpendsVersionId && (
                    <span className="element">
                      <label>
                        Ordered Spends Version:{' '}
                        <label className="strong">{state.OrderedSpendsVersionDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  <span className="element">
                    <label>
                      Minimum Spending: <label className="strong">{state.Minimum}</label>
                    </label>
                  </span>
                </div>
                <div className="content-line">
                  <span className="element">
                    <label>
                      Start Date: <label className="strong">{formatDate(state.StartDate)}</label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      End Date: <label className="strong">{formatDate(state.EndDate)}</label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Amount: <label className="strong">{state.Amount}</label>
                    </label>
                  </span>
                </div>
                <div className="content-line">
                  <span className="element">
                    <label>
                      Deal Year: <label className="strong">{state.DealYear}</label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Rebate: <label className="strong">{state.Rebate}</label>
                    </label>
                  </span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="DealCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="DealLabelBlockedCode">Blocked</label>
                  <input id="DealCheckBlockedCode" defaultChecked name="DealCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterVendorId">Master Vendor</label>
                  <select
                    className=""
                    id="DealMasterVendorId"
                    name="MasterVendorId"
                    defaultValue={state.MasterVendorId}
                    required
                    data-required-msg="Master  Vendor is required"
                  >
                    {state.MasterVendorId && (
                      <option value={state.MasterVendorId}>{state.MasterVendorDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="OrderedSpendsVersionId">Ordered Spends Version</label>
                  <select
                    className=""
                    id="DealOrderedSpendsVersionId"
                    name="OrderedSpendsVersionId"
                    defaultValue={state.OrderedSpendsVersionId}
                    required
                    data-required-msg="Ordered  Spends  Version is required"
                  >
                    {state.OrderedSpendsVersionId && (
                      <option value={state.OrderedSpendsVersionId}>{state.OrderedSpendsVersionDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="Alias">Alias</label>
                  <input
                    required
                    data-required-msg="Alias is required"
                    id="DealAlias"
                    name="Alias"
                    type="text"
                    defaultValue={state.Alias}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="Minimum">Minimum Spending</label>
                  <input
                    required
                    data-required-msg="Minimum Spending is required"
                    data-val-number="The field Minimum Spending must be a number."
                    id="DealMinimum"
                    name="Minimum"
                    type="text"
                    defaultValue={state.Minimum}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="RebateCalculation" className="required">
                    Rebate Calculation
                  </label>
                  <select
                    className=""
                    id="DealRebateCalculation"
                    name="RebateCalculation"
                    value={state.RebateCalculation}
                    required
                    data-required-msg="Rebate Calculation is required"
                  >
                    <option value="0">Fixed</option>
                    <option value="1">Tier</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="col-md-2">
                  <label htmlFor="StartDate">Start Date</label>
                  <input
                    required
                    data-required-msg="Start Date is required"
                    id="DealStartDate"
                    name="StartDate"
                    type="text"
                    defaultValue={state.StartDate}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="EndDate">End Date</label>
                  <input
                    required
                    data-required-msg="End Date is required"
                    id="DealEndDate"
                    name="EndDate"
                    type="text"
                    defaultValue={state.EndDate}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="DealYear">Deal Year</label>
                  <input
                    required
                    data-required-msg="Deal Year is required"
                    data-val-number="The field Deal Year must be a number."
                    id="DealDealYear"
                    name="DealYear"
                    type="text"
                    defaultValue={state.DealYear}
                    className="k-input k-textbox"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="DealSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="DealSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select className="" id="DealSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source}>
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarDeal">
                <div className="left">
                  <button id="cancelDealButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveDealButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <div id="DealTabstrip">
            <ul>
              <li id="DealDealCondition" className="k-state-active">
                <i className="fas fa-balance-scale"></i>
                <span className="labeltext">Conditions</span>
              </li>
              <li id="DealDealTier">
                <i className="fas fa-balance-scale"></i>
                <span className="labeltext">Tier</span>
              </li>
              <li id="DealOrderedSpend">
                <i className="fas fa-shopping-cart"></i>
                <span className="labeltext">Candidates</span>
              </li>
              <li id="DealDealBreakDown">
                <i className="fas fa-balance-scale"></i>
                <span className="labeltext">Distribution</span>
              </li>
            </ul>
            <div>
              <div id="associationDealDealCondition">
                <div className="k-block grid-details">
                  <h2>Conditions</h2>
                  <div id="DealConditionGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
            <div>
              <div id="associationDealDealTier">
                <div className="k-block grid-details">
                  <h2>Tier</h2>
                  <div id="DealTierGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
            <div>
              <div id="associationDealOrderedSpend">
                <div className="k-block grid-details">
                  <h2>Billed Spends</h2>
                  <div id="OrderedSpendGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
            <div>
              <div id="associationDealDealBreakDown">
                <div className="k-block grid-details">
                  <h2>Tier</h2>
                  <div id="DealBreakDownGrid" className="grid-control"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
