import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getEntityDetails, getPreferredDetails } from '../../../../services/ApiServices';

export function TradingSACConfigNewPage(): React.ReactElement {
  const [state, setState] = useState<ITradingSACConfig>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'SACConfig');
  const updatedState: ITradingSACConfig = { Id: '', LoadingInfo: {} };
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      setState(updatedState);
      appendScriptsCallback(
        [
          'Areas/Trading/Scripts/Resources/en/res.SACConfig.en.js',
          'Areas/Trading/Scripts/Resources/en/Types.en.js',
          'Areas/Trading/Scripts/Enums.js',
          'Areas/Trading/Scripts/SACConfig/View.js',
        ],
        'initNewTradingSACConfig',
        { viewmodel: updatedState, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="SACConfig" className="controls-container default block">
              <h3>
                Create a New <b>SAC</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="SACConfigCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="SACConfigLabelBlockedCode">Blocked</label>
                  <input
                    id="SACConfigCheckBlockedCode"
                    defaultChecked
                    name="SACConfigCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaVendorId">Media Vendor</label>
                  <select
                    className=""
                    id="SACConfigMediaVendorId"
                    name="MediaVendorId"
                    defaultValue={state.MediaVendorId}
                    required
                    data-required-msg="Media  Vendor is required"
                  >
                    {state.MediaVendorId && (
                      <option value={state.MediaVendorId}>{state.MediaVendorDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="SACConfigSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarSACConfig">
                <div className="left">
                  <button id="cancelSACConfigButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveSACConfigButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface ITradingSACConfig {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  MediaVendorId?: string;
  MediaVendorDisplayValue?: string;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

export function TradingSACConfigDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<ITradingSACConfig>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Trading', 'SACConfig');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Trading', 'SACConfig', id).then((result) => {
        setState(result.Value[0] as ITradingSACConfig);
        appendScriptsCallback(
          [
            'Areas/Trading/Scripts/Resources/en/res.SACConfig.en.js',
            'Areas/Trading/Scripts/Resources/en/Types.en.js',
            'Areas/Trading/Scripts/Enums.js',
            'Areas/Trading/Scripts/SACConfig/View.js',
            'Areas/Trading/Scripts/Resources/en/res.SACLines.en.js',
            'Areas/Trading/Scripts/SACLines/View.js',
          ],
          'initDetailTradingSACConfig',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadTradingSACConfigState'] = (data) => {
    setState(data.Value[0] as ITradingSACConfig);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="SACConfig" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      SAC: <span>{state.Display}</span>
                      <span
                        id="SACConfigToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.MediaVendorId && (
                    <span className="element">
                      <label>
                        Media Vendor: <label className="strong">{state.MediaVendorDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="SACConfigCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="SACConfigLabelBlockedCode">Blocked</label>
                  <input
                    id="SACConfigCheckBlockedCode"
                    defaultChecked
                    name="SACConfigCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaVendorId">Media Vendor</label>
                  <select
                    className=""
                    id="SACConfigMediaVendorId"
                    name="MediaVendorId"
                    defaultValue={state.MediaVendorId}
                    required
                    data-required-msg="Media  Vendor is required"
                  >
                    {state.MediaVendorId && (
                      <option value={state.MediaVendorId}>{state.MediaVendorDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="SACConfigSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="SACConfigSourceStatus"
                    name="LoadingInfo.SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select className="" id="SACConfigSource" name="LoadingInfo.Source" value={state.LoadingInfo.Source}>
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarSACConfig">
                <div className="left">
                  <button id="cancelSACConfigButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveSACConfigButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <div id="associationSACConfigSACLines">
            <div className="k-block grid-details">
              <h2>SAC Config</h2>
              <div id="SACLinesGrid" className="grid-control"></div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
