import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
import { getEntityDetails } from '../../../../services/ApiServices';

export function MasterRevenueStreamNewPage(): React.ReactElement {
  const [state] = useState<IMasterRevenueStream>({ Id: '', LoadingInfo: {}, Sequence: '0' });
  const currentSecurity = useSecurity('Master', 'RevenueStream');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Master/Scripts/Resources/en/res.RevenueStream.en.js',
          'Areas/Master/Scripts/Resources/en/Types.en.js',
          'Areas/Master/Scripts/Enums.js',
          'Areas/Master/Scripts/RevenueStream/View.js',
        ],
        'initNewMasterRevenueStream',
        { viewmodel: state, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="RevenueStream" className="controls-container default block">
              <h3>
                Create a New <b>Revenue Stream</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="RevenueStreamCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="RevenueStreamLabelBlockedCode">Blocked</label>
                  <input
                    id="RevenueStreamCheckBlockedCode"
                    defaultChecked
                    name="RevenueStreamCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="RevenueStreamEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() => window['OnChangeReply']('RevenueStreamEnglishName', 'RevenueStreamNativeName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="RevenueStreamNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="RevenueStreamEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() =>
                      window['OnChangeReply']('RevenueStreamEnglishShortName', 'RevenueStreamNativeShortName')
                    }
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="RevenueStreamNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="CompanyId">Company</label>
                  <select className="" id="RevenueStreamCompanyId" name="CompanyId" defaultValue={state.CompanyId}>
                    {state.CompanyId && <option value={state.CompanyId}>{state.CompanyDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaTypeId">Media Type</label>
                  <select
                    className=""
                    id="RevenueStreamMediaTypeId"
                    name="MediaTypeId"
                    defaultValue={state.MediaTypeId}
                  >
                    {state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaSubTypeId">Media Sub Type</label>
                  <select
                    className=""
                    id="RevenueStreamMediaSubTypeId"
                    name="MediaSubTypeId"
                    defaultValue={state.MediaSubTypeId}
                  >
                    {state.MediaSubTypeId && (
                      <option value={state.MediaSubTypeId}>{state.MediaSubTypeDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="AccountType" className="required">
                    Account Type
                  </label>
                  <select
                    className=""
                    id="RevenueStreamAccountType"
                    name="AccountType"
                    value={state.AccountType}
                    required
                    data-required-msg="Account Type is required"
                  >
                    <option value="0">Commission</option>
                    <option value="1">Fee</option>
                    <option value="2">Both</option>
                    <option value="3">None</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="AllowForecast" className="required">
                    Allow Forecast
                  </label>
                  <select
                    className=""
                    id="RevenueStreamAllowForecast"
                    name="AllowForecast"
                    value={state.AllowForecast}
                    required
                    data-required-msg="Allow Forecast is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="AllowManualRevenue" className="required">
                    Allow Manual Revenue
                  </label>
                  <select
                    className=""
                    id="RevenueStreamAllowManualRevenue"
                    name="AllowManualRevenue"
                    value={state.AllowManualRevenue}
                    required
                    data-required-msg="Allow Manual Revenue is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="FocusOn" className="required">
                    Focus On
                  </label>
                  <select
                    className=""
                    id="RevenueStreamFocusOn"
                    name="FocusOn"
                    value={state.FocusOn}
                    required
                    data-required-msg="Focus On is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2">
                  <label htmlFor="Sequence" className="required">
                    Sequence
                  </label>
                  <input
                    data-required-msg="Sequence is required"
                    data-val="true"
                    data-val-number="The field Sequence must be a number."
                    defaultValue={state.Sequence}
                    data-val-required="The Sequence field is required."
                    id="RevenueStreamSequence"
                    min="-2147483648"
                    name="Sequence"
                    required
                    type="text"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="RevenueStreamSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarRevenueStream">
                <div className="left">
                  <button id="cancelRevenueStreamButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveRevenueStreamButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMasterRevenueStream {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  EnglishName?: string;
  EnglishShortName?: string;
  NativeName?: string;
  NativeShortName?: string;
  Sequence?: string;
  CompanyId?: string;
  CompanyDisplayValue?: string;
  MediaTypeId?: string;
  MediaTypeDisplayValue?: string;
  MediaSubTypeId?: string;
  MediaSubTypeDisplayValue?: string;
  AccountType?: number;
  AllowForecast?: number;
  AllowManualRevenue?: number;
  FocusOn?: number;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ApplianceModeEnum {
  YES = 0,
  NO = 1,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum RevenueAccountEnum {
  COMMISSION = 0,
  FEE = 1,
  BOTH = 2,
  NONE = 3,
}

export function MasterRevenueStreamDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMasterRevenueStream>({ Id: '', LoadingInfo: {}, Sequence: '0' });
  const currentSecurity = useSecurity('Master', 'RevenueStream');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Master', 'RevenueStream', id).then((result) => {
        setState(result.Value[0] as IMasterRevenueStream);
        appendScriptsCallback(
          [
            'Areas/Master/Scripts/Resources/en/res.RevenueStream.en.js',
            'Areas/Master/Scripts/Resources/en/Types.en.js',
            'Areas/Master/Scripts/Enums.js',
            'Areas/Master/Scripts/RevenueStream/View.js',
          ],
          'initDetailMasterRevenueStream',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadMasterRevenueStreamState'] = (data) => {
    setState(data.Value[0] as IMasterRevenueStream);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="RevenueStream" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Revenue Stream: <span>{state.Display}</span>
                      <span
                        id="RevenueStreamToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.CompanyId && (
                    <span className="element">
                      <label>
                        Company: <label className="strong">{state.CompanyDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.MediaTypeId && (
                    <span className="element">
                      <label>
                        Media Type: <label className="strong">{state.MediaTypeDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.MediaSubTypeId && (
                    <span className="element">
                      <label>
                        Media Sub Type: <label className="strong">{state.MediaSubTypeDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
                <div className="content-line">
                  <span className="element">
                    <label>
                      Allow Forecast:{' '}
                      <label className="strong">
                        {state.AllowForecast != undefined ? ApplianceModeEnum[state.AllowForecast] : ''}
                      </label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Allow Manual Revenue:{' '}
                      <label className="strong">
                        {state.AllowManualRevenue != undefined ? ApplianceModeEnum[state.AllowManualRevenue] : ''}
                      </label>
                    </label>
                  </span>
                  <span className="element">
                    <label>
                      Focus On:{' '}
                      <label className="strong">
                        {state.FocusOn != undefined ? ApplianceModeEnum[state.FocusOn] : ''}
                      </label>
                    </label>
                  </span>
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="RevenueStreamCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="RevenueStreamLabelBlockedCode">Blocked</label>
                  <input
                    id="RevenueStreamCheckBlockedCode"
                    defaultChecked
                    name="RevenueStreamCheckBlockedCode"
                    type="checkbox"
                  />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="RevenueStreamEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() => window['OnChangeReply']('RevenueStreamEnglishName', 'RevenueStreamNativeName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="RevenueStreamNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="RevenueStreamEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() =>
                      window['OnChangeReply']('RevenueStreamEnglishShortName', 'RevenueStreamNativeShortName')
                    }
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="RevenueStreamNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="CompanyId">Company</label>
                  <select className="" id="RevenueStreamCompanyId" name="CompanyId" defaultValue={state.CompanyId}>
                    {state.CompanyId && <option value={state.CompanyId}>{state.CompanyDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaTypeId">Media Type</label>
                  <select
                    className=""
                    id="RevenueStreamMediaTypeId"
                    name="MediaTypeId"
                    defaultValue={state.MediaTypeId}
                  >
                    {state.MediaTypeId && <option value={state.MediaTypeId}>{state.MediaTypeDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MediaSubTypeId">Media Sub Type</label>
                  <select
                    className=""
                    id="RevenueStreamMediaSubTypeId"
                    name="MediaSubTypeId"
                    defaultValue={state.MediaSubTypeId}
                  >
                    {state.MediaSubTypeId && (
                      <option value={state.MediaSubTypeId}>{state.MediaSubTypeDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="AccountType" className="required">
                    Account Type
                  </label>
                  <select
                    className=""
                    id="RevenueStreamAccountType"
                    name="AccountType"
                    value={state.AccountType}
                    required
                    data-required-msg="Account Type is required"
                  >
                    <option value="0">Commission</option>
                    <option value="1">Fee</option>
                    <option value="2">Both</option>
                    <option value="3">None</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="AllowForecast" className="required">
                    Allow Forecast
                  </label>
                  <select
                    className=""
                    id="RevenueStreamAllowForecast"
                    name="AllowForecast"
                    value={state.AllowForecast}
                    required
                    data-required-msg="Allow Forecast is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="AllowManualRevenue" className="required">
                    Allow Manual Revenue
                  </label>
                  <select
                    className=""
                    id="RevenueStreamAllowManualRevenue"
                    name="AllowManualRevenue"
                    value={state.AllowManualRevenue}
                    required
                    data-required-msg="Allow Manual Revenue is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="FocusOn" className="required">
                    Focus On
                  </label>
                  <select
                    className=""
                    id="RevenueStreamFocusOn"
                    name="FocusOn"
                    value={state.FocusOn}
                    required
                    data-required-msg="Focus On is required"
                  >
                    <option value="0">Yes</option>
                    <option value="1">No</option>
                  </select>
                </div>
                <div className="col-md-2">
                  <label htmlFor="Sequence" className="required">
                    Sequence
                  </label>
                  <input
                    data-required-msg="Sequence is required"
                    data-val="true"
                    data-val-number="The field Sequence must be a number."
                    defaultValue={state.Sequence}
                    data-val-required="The Sequence field is required."
                    id="RevenueStreamSequence"
                    min="-2147483648"
                    name="Sequence"
                    required
                    type="text"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="RevenueStreamSourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="RevenueStreamSourceStatus"
                    name="SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select className="" id="RevenueStreamSource" name="Source" value={state.LoadingInfo.Source}>
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarRevenueStream">
                <div className="left">
                  <button id="cancelRevenueStreamButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveRevenueStreamButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}
