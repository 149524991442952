import { IMenuItemProps } from '../components/services/GraphQLShared';

export const sidebar_menu: IMenuItemProps[] = [
  { id: 0, title: 'Home', icon: 'home', route: '/', key: '' },
  {
    title: 'Trading',
    key: 'Trading',
    icon: 'calculator',
    route: '#',
    id: 1,
  },
  {
    title: 'Revenue Forecasts',
    key: 'RevenueForecast',
    icon: 'tablet-alt',
    route: '/Trading/RevenueForecastStream/IndexForecast',
    id: 2,
    parentId: 1,
  },
  {
    title: 'Period Management',
    key: 'PeriodManagement',
    icon: 'folder-tree',
    route: '/Trading/AgencyProfile/IndexRevenuePeriod',
    id: 3,
    parentId: 1,
  },
  {
    title: 'Trading Forecast Adjustment',
    key: 'Trading',
    icon: 'wrench',
    route: '#',
    id: 50,
  },
  {
    title: 'Forecast Adjustment',
    key: 'PeriodManagement',
    icon: 'sliders-h',
    route: '/Trading/RevenueForecastStream/RevenueForecastStreamUpload',
    id: 51,
    parentId: 50,
  },
  {
    title: 'Trading Settings',
    key: 'TradingSettings',
    icon: 'sliders-h',
    route: '#',
    id: 7,
  },
  {
    title: 'Agency Profiles',
    key: 'AgencyProfiles',
    icon: 'folder-tree',
    route: '/Trading/AgencyProfile/Index',
    id: 8,
    parentId: 7,
  },
  {
    title: 'Client Profiles',
    key: 'ClientProfiles',
    icon: 'tablet-alt',
    route: '/Trading/ClientProfile/Index',
    id: 9,
    parentId: 7,
  },
  {
    title: 'Forecast Audit Trail',
    key: 'ForecastAuditTrail',
    icon: 'list-ol',
    route: '/Trading/ForecastAuditTrail/Index',
    id: 10,
    parentId: 7,
  },
  {
    title: 'Trading Reporting',
    key: 'TradingReporting',
    icon: 'file-alt',
    route: '#',
    id: 4,
  },
  {
    title: 'Excel Reports',
    key: 'ExcelReports',
    icon: 'tablet-alt',
    route: '/Report/Index/SpendReports',
    id: 5,
    parentId: 4,
  },
  {
    title: 'Graph Reports',
    key: 'GraphReports',
    icon: 'chart-pie',
    route: '/Graph/Index/SpendReports',
    id: 6,
    parentId: 4,
  },
  {
    title: 'Trading Reporting (R)',
    key: 'TradingReportingR',
    icon: 'file-alt',
    route: '#',
    id: 44,
  },
  {
    title: 'Excel Reports',
    key: 'ExcelReports',
    icon: 'tablet-alt',
    route: '/Report/Index/SpendReportsR',
    id: 5,
    parentId: 44,
  },
  {
    title: 'Graph Reports',
    key: 'GraphReports',
    icon: 'chart-pie',
    route: '/Graph/Index/SpendReportsR',
    id: 6,
    parentId: 44,
  },
  {
    title: 'Deals',
    key: 'Deals',
    icon: 'sliders-h',
    route: '#',
    id: 11,
  },
  {
    title: 'Deals Management',
    key: 'DealsManagement',
    icon: 'tablet-alt',
    route: '/Trading/Deal/Index',
    id: 12,
    parentId: 11,
  },
  {
    title: 'Spends Sets',
    key: 'SpendsSets',
    icon: 'tablet-alt',
    route: '/Trading/SpendsSet/Index',
    id: 13,
    parentId: 11,
  },
  {
    title: 'Spends Set Upload',
    key: 'SpendsSetUpload',
    icon: 'tablet-alt',
    route: '/Trading/SpendsSet/SpendsSetUpload',
    id: 14,
    parentId: 11,
  },
  {
    title: 'SAC Config',
    key: 'SACConfig',
    icon: 'tablet-alt',
    route: '/Trading/SACConfig/Index',
    id: 14,
    parentId: 11,
  },
  {
    title: 'Deal Reporting',
    key: 'DealReporting',
    icon: 'file-alt',
    route: '#',
    id: 15,
  },
  {
    title: 'Excel Reports',
    key: 'ExcelReports',
    icon: 'tablet-alt',
    route: '/Report/Index/DealReports',
    id: 16,
    parentId: 15,
  },
  {
    title: 'Master',
    key: 'Master',
    icon: 'table',
    route: '#',
    id: 17,
  },
  {
    title: 'Master Agencies',
    key: 'MasterAgencies',
    icon: 'tablet-alt',
    route: '/Master/MasterCompany/Index',
    id: 18,
    parentId: 17,
  },
  {
    title: 'Agencies',
    key: 'Agencies',
    icon: 'tablet-alt',
    route: '/Master/Company/Index',
    id: 19,
    parentId: 17,
  },
  {
    title: 'Clients',
    key: 'Clients',
    icon: 'tablet-alt',
    route: '/Master/Client/Index',
    id: 20,
    parentId: 17,
  },
  {
    title: 'Client Sets',
    key: 'ClientSets',
    icon: 'tablet-alt',
    route: '/Master/ClientSet/Index',
    id: 21,
    parentId: 17,
  },
  {
    title: 'Master Clients',
    key: 'MasterClients',
    icon: 'tablet-alt',
    route: '/Master/MasterClient/Index',
    id: 22,
    parentId: 17,
  },
  {
    title: 'Media Types',
    key: 'MediaTypes',
    icon: 'tablet-alt',
    route: '/Master/MediaType/Index',
    id: 23,
    parentId: 17,
  },
  {
    title: 'Master Media Types',
    key: 'MasterMediaTypes',
    icon: 'tablet-alt',
    route: '/Master/MasterMediaType/Index',
    id: 24,
    parentId: 17,
  },
  {
    title: 'Revenue Streams',
    key: 'RevenueStreams',
    icon: 'tablet-alt',
    route: '/Master/RevenueStream/Index',
    id: 25,
    parentId: 17,
  },
  {
    title: 'Master Vendors',
    key: 'MasterVendors',
    icon: 'tablet-alt',
    route: '/Master/MasterVendor/Index',
    id: 26,
    parentId: 17,
  },
  {
    title: 'Master Media Owners',
    key: 'MasterMediaOwners',
    icon: 'tablet-alt',
    route: '/Master/MasterMediaOwner/Index',
    id: 27,
    parentId: 17,
  },
  {
    title: 'Media Owners',
    key: 'MediaOwners',
    icon: 'tablet-alt',
    route: '/Master/MediaOwner/Index',
    id: 28,
    parentId: 17,
  },
  {
    title: 'Media Vendors',
    key: 'MediaVendors',
    icon: 'tablet-alt',
    route: '/Master/MediaVendor/Index',
    id: 29,
    parentId: 17,
  },
  {
    title: 'Sale Houses',
    key: 'SaleHouses',
    icon: 'tablet-alt',
    route: '/Master/SaleHouse/Index',
    id: 30,
    parentId: 17,
  },
  {
    title: 'Currencies',
    key: 'Currencies',
    icon: 'tablet-alt',
    route: '/Master/Currency/Index',
    id: 31,
    parentId: 17,
  },
  {
    title: 'Languages',
    key: 'Languages',
    icon: 'tablet-alt',
    route: '/Master/Language/Index',
    id: 32,
    parentId: 17,
  },
  {
    title: 'Markets',
    key: 'Markets',
    icon: 'tablet-alt',
    route: '/Master/Market/Index',
    id: 33,
    parentId: 17,
  },
  {
    title: 'Regions',
    key: 'Regions',
    icon: 'tablet-alt',
    route: '/Master/Region/Index',
    id: 34,
    parentId: 17,
  },
  {
    title: 'Security',
    key: 'SecurityMenu',
    icon: 'users',
    route: '#',
    id: 35,
  },
  {
    title: 'Roles',
    key: 'Roles',
    icon: 'tablet-alt',
    route: '/Framework/Role/Index',
    id: 36,
    parentId: 35,
  },
  {
    title: 'Security Groups',
    key: 'SecurityGroups',
    icon: 'tablet-alt',
    route: '/Framework/Group/Index',
    id: 37,
    parentId: 35,
  },
  {
    title: 'Users',
    key: 'Users',
    icon: 'tablet-alt',
    route: '/Framework/User/Index',
    id: 38,
    parentId: 35,
  },
  {
    title: 'Users and Groups',
    key: 'UsersandGroups',
    icon: 'tablet-alt',
    route: '/Framework/User/IndexTree',
    id: 39,
    parentId: 35,
  },
  {
    title: 'Security Reports',
    key: 'SecurityReports',
    icon: 'tablet-alt',
    route: '/Report/Index/Security',
    id: 40,
    parentId: 35,
  },
  {
    title: 'Integration',
    key: 'IntegrationMenu',
    icon: 'broadcast-tower',
    route: '#',
    id: 41,
  },
  {
    title: 'Definitions',
    key: 'Definitions',
    icon: 'tablet-alt',
    route: '/Framework/IntegrationDefinition/Index',
    id: 42,
    parentId: 41,
  },
  {
    title: 'Messages and Processes',
    key: 'MessagesandProcesses',
    icon: 'tablet-alt',
    route: '/Framework/IntegrationProcess/Index',
    id: 43,
    parentId: 41,
  },
  //{
  //  title: 'Control Panel',
  //  key: 'ControlPanel',
  //  icon: 'tablet-alt',
  //  route: '/Framework/IntegrationProcess/Panel',
  //  id: 44,
  //  parentId: 41,
  //},
  {
    title: 'Administration',
    key: 'AdminMenu',
    icon: 'wrench',
    route: '#',
    id: 45,
  },
  {
    title: 'Documents',
    key: 'Documents',
    icon: 'tablet-alt',
    route: '/Framework/DocumentFile/Index',
    id: 46,
    parentId: 45,
  },
  {
    title: 'Application Settings',
    key: 'ApplicationSettings',
    icon: 'tablet-alt',
    route: '/Framework/AppSettings/Index',
    id: 47,
    parentId: 45,
  },
  {
    title: 'Data Administration',
    key: 'DataAdministration',
    icon: 'tablet-alt',
    route: '/Framework/DataAdmin/Index',
    id: 48,
    parentId: 45,
  },
  {
    title: 'Insights Viewer',
    key: 'InsightsViewer',
    icon: 'tablet-alt',
    route: '/Framework/InsightsViewer/Index',
    id: 49,
    parentId: 45,
  },
];
export const app_themes = [
  { id: 'light', text: 'Light' },
  { id: 'dark', text: 'Dark' },
];
