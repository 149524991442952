import React, { useState } from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { appendScriptsCallback, formatDate, formatDateTime } from '../../../helpers/Utils';
import { useParams } from 'react-router-dom';
import { getEntityDetails } from '../../../../services/ApiServices';

export function MasterCompanyNewPage(): React.ReactElement {
  const [state] = useState<IMasterCompany>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Master', 'Company');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScriptsCallback(
        [
          'Areas/Master/Scripts/Resources/en/res.Company.en.js',
          'Areas/Master/Scripts/Resources/en/Types.en.js',
          'Areas/Master/Scripts/Enums.js',
          'Areas/Master/Scripts/Company/View.js',
        ],
        'initNewMasterCompany',
        { viewmodel: state, canEdit: true, canCreate: true, canRemove: true },
      );
    }
  }, []);
  return (
    <div className="container-fluid">
      <section id="patron">
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Company" className="controls-container default block">
              <h3>
                Create a New <b>Agency</b>
              </h3>
              <p>Please fill in the fields, once completed click on Save to proceed with the creation:</p>
              <form id="form" action="" className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="CompanyCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="CompanyLabelBlockedCode">Blocked</label>
                  <input id="CompanyCheckBlockedCode" defaultChecked name="CompanyCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="CompanyEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() => window['OnChangeReply']('CompanyEnglishName', 'CompanyNativeName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="CompanyNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="CompanyEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() => window['OnChangeReply']('CompanyEnglishShortName', 'CompanyNativeShortName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="CompanyNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MarketId">Market</label>
                  <select
                    className=""
                    id="CompanyMarketId"
                    name="MarketId"
                    defaultValue={state.MarketId}
                    required
                    data-required-msg="Market is required"
                  >
                    {state.MarketId && <option value={state.MarketId}>{state.MarketDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterCompanyId">Master Company</label>
                  <select
                    className=""
                    id="CompanyMasterCompanyId"
                    name="MasterCompanyId"
                    defaultValue={state.MasterCompanyId}
                    required
                    data-required-msg="Master  Company is required"
                  >
                    {state.MasterCompanyId && (
                      <option value={state.MasterCompanyId}>{state.MasterCompanyDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="BillingReference">Billing Reference</label>
                  <input
                    id="CompanyBillingReference"
                    name="BillingReference"
                    type="text"
                    defaultValue={state.BillingReference}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="CorporateColor">Corporate Color</label>
                  <input
                    required
                    data-required-msg="Corporate Color is required"
                    id="CompanyCorporateColor"
                    name="CorporateColor"
                    type="text"
                    defaultValue={state.CorporateColor}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Type" className="required">
                    Type
                  </label>
                  <select
                    className=""
                    id="CompanyType"
                    name="Type"
                    value={state.Type}
                    required
                    data-required-msg="Type is required"
                  >
                    <option value="5">Back Office</option>
                    <option value="4">Media Specialist</option>
                    <option value="3">Creative Agency</option>
                    <option value="2">Product Incubation</option>
                    <option value="1">Buying Central</option>
                    <option value="0">Media Agency</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="CompanySourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
              </form>
              <section className="button-toolbar" id="ToolbarCompany">
                <div className="left">
                  <button id="cancelCompanyButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveCompanyButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
}

interface IMasterCompany {
  LoadingInfo: {
    CreationDate?: string;
    CreatedBy?: string;
    CreatedByDisplayValue?: string;
    UpdateDate?: string;
    UpdatedBy?: string;
    UpdatedByDisplayValue?: string;
    SourceStatus?: number;
    Source?: number;
  };
  EnglishName?: string;
  EnglishShortName?: string;
  NativeName?: string;
  NativeShortName?: string;
  MarketId?: string;
  MarketDisplayValue?: string;
  MasterCompanyId?: string;
  MasterCompanyDisplayValue?: string;
  BillingReference?: string;
  CorporateColor?: string;
  Type?: number;
  Code?: string;
  Display?: string;
  EntityStatus?: number;
  SourceReference?: string;
  Id: string;
  InactiveFrom?: Date;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum CompanyTypeEnum {
  BACKOFFICE = 5,
  MEDIASPECIALIST = 4,
  CREATIVEAGENCY = 3,
  PRODUCTINCUBATION = 2,
  BUYINGCENTRAL = 1,
  MEDIAAGENCY = 0,
}

export function MasterCompanyDetailPage(): React.ReactElement {
  const { id } = useParams();
  const [state, setState] = useState<IMasterCompany>({ Id: '', LoadingInfo: {} });
  const currentSecurity = useSecurity('Master', 'Company');
  React.useEffect(() => {
    if (state.Id == '' && currentSecurity.checkAccess()) {
      getEntityDetails('Master', 'Company', id).then((result) => {
        setState(result.Value[0] as IMasterCompany);
        appendScriptsCallback(
          [
            'Areas/Master/Scripts/Resources/en/res.Company.en.js',
            'Areas/Master/Scripts/Resources/en/Types.en.js',
            'Areas/Master/Scripts/Enums.js',
            'Areas/Master/Scripts/Company/View.js',
            'Areas/Master/Scripts/Resources/en/res.Office.en.js',
            'Areas/Master/Scripts/Office/View.js',
          ],
          'initDetailMasterCompany',
          { viewmodel: result.Value[0], canEdit: true, canCreate: true, canRemove: true, id: id },
        );
      });
    }
  }, []);
  window['reloadMasterCompanyState'] = (data) => {
    setState(data.Value[0] as IMasterCompany);
  };
  return (
    <div className="container-fluid">
      <section id="patron" style={{ display: 'none' }}>
        <section className="content">
          <div className="clear"></div>
          <section id="viewContent">
            <div id="Company" className="controls-container default block">
              <div className="content-label">
                <div className="content-title">
                  <div className="k-content">
                    <h3>
                      Agency: <span>{state.Display}</span>
                      <span
                        id="CompanyToggle"
                        className="k-icon k-i-media-manager"
                        style={{ cursor: 'pointer' }}
                      ></span>
                    </h3>
                  </div>
                </div>
                <div className="content-line">
                  {state.MarketId && (
                    <span className="element">
                      <label>
                        Market: <label className="strong">{state.MarketDisplayValue}</label>
                      </label>
                    </span>
                  )}
                  {state.MasterCompanyId && (
                    <span className="element">
                      <label>
                        Master Company: <label className="strong">{state.MasterCompanyDisplayValue}</label>
                      </label>
                    </span>
                  )}
                </div>
              </div>
              <form id="form" action="" style={{ display: 'none' }} className="content-view row">
                <div className="col-md-2">
                  <label>Code</label>
                  <input
                    id="CompanyCode"
                    max="20"
                    name="Code"
                    type="text"
                    defaultValue={state.Code}
                    className="shortcontrol disabled k-input k-input-solid k-textbox"
                    readOnly
                    disabled
                    placeholder="AUTO-CODE"
                  />
                </div>
                <div className="col-md-2">
                  <label id="CompanyLabelBlockedCode">Blocked</label>
                  <input id="CompanyCheckBlockedCode" defaultChecked name="CompanyCheckBlockedCode" type="checkbox" />
                </div>
                <div className="clear"></div>
                <div className="col-md-4">
                  <label htmlFor="EnglishName" className="required">
                    Name (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (EN) is required"
                    id="CompanyEnglishName"
                    max="255"
                    min="1"
                    name="EnglishName"
                    required
                    type="text"
                    defaultValue={state.EnglishName}
                    onChange={() => window['OnChangeReply']('CompanyEnglishName', 'CompanyNativeName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeName" className="required">
                    Name (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="Name (NT) is required"
                    id="CompanyNativeName"
                    max="255"
                    min="1"
                    name="NativeName"
                    required
                    type="text"
                    defaultValue={state.NativeName}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="EnglishShortName" className="required">
                    ShortName (EN)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (EN) is required"
                    id="CompanyEnglishShortName"
                    max="50"
                    min="1"
                    name="EnglishShortName"
                    required
                    type="text"
                    defaultValue={state.EnglishShortName}
                    onChange={() => window['OnChangeReply']('CompanyEnglishShortName', 'CompanyNativeShortName')}
                  />
                </div>
                <div className="col-md-4  ">
                  <label htmlFor="NativeShortName" className="required">
                    ShortName (NT)
                  </label>
                  <input
                    className="k-input k-input-solid k-textbox"
                    data-required-msg="ShortName (NT) is required"
                    id="CompanyNativeShortName"
                    max="50"
                    min="1"
                    name="NativeShortName"
                    required
                    type="text"
                    defaultValue={state.NativeShortName}
                  />
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MarketId">Market</label>
                  <select
                    className=""
                    id="CompanyMarketId"
                    name="MarketId"
                    defaultValue={state.MarketId}
                    required
                    data-required-msg="Market is required"
                  >
                    {state.MarketId && <option value={state.MarketId}>{state.MarketDisplayValue}</option>}
                  </select>
                </div>
                <div className="col-md-4 combobox  ">
                  <label htmlFor="MasterCompanyId">Master Company</label>
                  <select
                    className=""
                    id="CompanyMasterCompanyId"
                    name="MasterCompanyId"
                    defaultValue={state.MasterCompanyId}
                    required
                    data-required-msg="Master  Company is required"
                  >
                    {state.MasterCompanyId && (
                      <option value={state.MasterCompanyId}>{state.MasterCompanyDisplayValue}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="BillingReference">Billing Reference</label>
                  <input
                    id="CompanyBillingReference"
                    name="BillingReference"
                    type="text"
                    defaultValue={state.BillingReference}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor="CorporateColor">Corporate Color</label>
                  <input
                    required
                    data-required-msg="Corporate Color is required"
                    id="CompanyCorporateColor"
                    name="CorporateColor"
                    type="text"
                    defaultValue={state.CorporateColor}
                    className="k-input k-input-solid k-textbox"
                  />
                </div>
                <div className="col-md-2 dropdownlist  ">
                  <label htmlFor="Type" className="required">
                    Type
                  </label>
                  <select
                    className=""
                    id="CompanyType"
                    name="Type"
                    value={state.Type}
                    required
                    data-required-msg="Type is required"
                  >
                    <option value="5">Back Office</option>
                    <option value="4">Media Specialist</option>
                    <option value="3">Creative Agency</option>
                    <option value="2">Product Incubation</option>
                    <option value="1">Buying Central</option>
                    <option value="0">Media Agency</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="SourceReference">Source Reference</label>
                  <input
                    id="CompanySourceReference"
                    max="60"
                    name="SourceReference"
                    type="text"
                    defaultValue={state.SourceReference}
                    className="shortcontrol k-input k-input-solid k-textbox"
                    placeholder="SOURCE-REFERENCE"
                  />
                </div>
                <div className="clear"></div>
                <div className="content-header">Tracking</div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_SourceStatus">Loading Status</label>
                  <select
                    className=""
                    id="CompanySourceStatus"
                    name="SourceStatus"
                    value={state.LoadingInfo.SourceStatus}
                  >
                    <option value="0">Loaded</option>
                    <option value="1">Processed</option>
                    <option value="2">Pending</option>
                    <option value="3">Reloaded</option>
                    <option value="4">Error</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <label htmlFor="LoadingInfo_Source">Source</label>
                  <select className="" id="CompanySource" name="Source" value={state.LoadingInfo.Source}>
                    <option value="0">Owned</option>
                    <option value="1">ExternalPlanning</option>
                    <option value="2">ExternalBuying</option>
                    <option value="3">ExternalFinance</option>
                    <option value="4">ExternalHub</option>
                  </select>
                </div>
                <div className="clear"></div>
                <div className="trackinfo">
                  <div className="line">
                    Created on <span className="when">{formatDateTime(state.LoadingInfo.CreationDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.CreatedByDisplayValue}</span>{' '}
                  </div>
                  <div className="line">
                    Last Update on <span className="when">{formatDateTime(state.LoadingInfo.UpdateDate)}</span> by{' '}
                    <span className="who">{state.LoadingInfo.UpdatedByDisplayValue}</span>{' '}
                  </div>
                </div>
              </form>
              <section className="button-toolbar" style={{ display: 'none' }} id="ToolbarCompany">
                <div className="left">
                  <button id="cancelCompanyButton" type="button" className="k-button button" title="Cancel">
                    Cancel
                  </button>
                </div>
                <div className="right">
                  <button id="saveCompanyButton" type="button" className="k-button button" title="Save">
                    Save
                  </button>
                </div>
              </section>
            </div>
          </section>
          <div id="associationCompanyOffice">
            <div className="k-block grid-details">
              <h2>Offices</h2>
              <div id="OfficeGrid" className="grid-control"></div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
