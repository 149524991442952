import React from 'react';
import { Route } from 'react-router';
import { Navigate } from 'react-router-dom';
import { IndexForecastPage } from '../components/pages/custom/IndexForecast';
import { IndexRevenuePeriodPage } from '../components/pages/custom/IndexRevenuePeriod';
import { SpendsSetUploadPage } from '../components/pages/custom/SpendsSetUpload';
import { RevenueForecastStreamUploadPage } from '../components/pages/custom/RevenueForecastStreamUpload';
import { TradingDealCustomPage } from '../components/pages/Trading/Deal/IndexPage.custom';
import {
  TradingDealDetailCustomPage,
  TradingDealCustomNewPage,
} from '../components/pages/Trading/Deal/ViewPage.custom';

export const createRoutesCustom = (isAuthenticated) => (
  <>
    <Route
      path="/Trading/RevenueForecastStream/IndexForecast"
      element={isAuthenticated ? <IndexForecastPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/RevenueForecastStream/IndexForecast/:id"
      element={isAuthenticated ? <IndexForecastPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/AgencyProfile/IndexRevenuePeriod"
      element={isAuthenticated ? <IndexRevenuePeriodPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/SpendsSet/SpendsSetUpload"
      element={isAuthenticated ? <SpendsSetUploadPage /> : <Navigate to="/" />}
    />
    <Route
      path="/Trading/RevenueForecastStream/RevenueForecastStreamUpload"
      element={isAuthenticated ? <RevenueForecastStreamUploadPage /> : <Navigate to="/" />}
    />
    <Route path="/Trading/Deal/Index" element={isAuthenticated ? <TradingDealCustomPage /> : <Navigate to="/" />} />
    <Route path="/Trading/Deal/New" element={isAuthenticated ? <TradingDealCustomNewPage /> : <Navigate to="/" />} />
    <Route path="/Trading/Deal/:id" element={isAuthenticated ? <TradingDealDetailCustomPage /> : <Navigate to="/" />} />
  </>
);
