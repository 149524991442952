import React from 'react';
import { useSecurity } from '../../../helpers/SecurityHooks';
import { appendScripts } from '../../../helpers/Utils';

export function MasterMediaTypePage(): React.ReactElement {
  const currentSecurity = useSecurity('Master', 'MediaType');
  React.useEffect(() => {
    if (currentSecurity.checkAccess()) {
      appendScripts([
        'Areas/Master/Scripts/Resources/en/res.MediaType.en.js',
        'Areas/Master/Scripts/Resources/en/Types.en.js',
        'Areas/Master/Scripts/Enums.js',
        'Areas/Master/Scripts/MediaType/Index.js',
      ]);
    }
  }, []);
  return (
    <div className="container-fluid">
      <header className="complete-grid">
        <div id="gridFilter1">
          <div id="gridfilter-toolbar"></div>
        </div>
      </header>
      <section id="gridContainer"></section>
      <ul id="gridContainer-menu"></ul>
    </div>
  );
}
